var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
var NewIpDispensingLog = /** @class */ (function (_super) {
    __extends(NewIpDispensingLog, _super);
    function NewIpDispensingLog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {
            ipNumber: {
                value: '',
                error: null,
                rule: 'required'
            },
            lotNumber: {
                value: '',
                error: null,
                rule: 'required'
            },
            dispensedAt: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            tabsDispensedCount: {
                value: '',
                error: null,
                rule: 'required|integer'
            },
            dispensingStaffName: {
                value: '',
                error: null,
                rule: 'required'
            },
            receivingStaffName: {
                value: '',
                error: null,
                rule: 'min:2'
            },
            returnedAt: {
                value: null,
                error: null,
                rule: 'date'
            },
            tabsReturnedCount: {
                value: '',
                error: null,
                rule: 'integer'
            },
            compliance: {
                value: '',
                error: null,
                rule: 'integer'
            }
        };
        _this.hasErrors = false;
        _this.errorMessage = '';
        _this.saving = false;
        _this.loading = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var ipDispensingStore, log, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDispensingStore = this.props.enrollmentStore.ipDispensingStore;
                        if (!this.isEdit) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ipDispensingStore.getIpDispensingLogById(this.ipDispensingLogId)];
                    case 2:
                        log = _a.sent();
                        this.assignData(log);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        return [3 /*break*/, 4];
                    case 4:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (log) {
            _this.data.ipNumber.value = log.ipNumber.toString();
            _this.data.lotNumber.value = log.lotNumber.toString();
            _this.data.dispensedAt.value = log.dispensedAt;
            _this.data.tabsDispensedCount.value = log.tabsDispensedCount.toString();
            _this.data.dispensingStaffName.value = log.dispensingStaffName.toString();
            _this.data.receivingStaffName.value = log.receivingStaffName || '';
            _this.data.returnedAt.value = log.returnedAt ? log.returnedAt : undefined;
            _this.data.tabsReturnedCount.value =
                log.tabsReturnedCount !== undefined ? log.tabsReturnedCount.toString() : '';
            _this.data.compliance.value = log.compliance !== undefined ? log.compliance.toString() : '';
            _this.data.ipNumber.value = log.ipNumber.toString();
        };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split((_this.isEdit ? '/editdi' : '') + "/" + (_this.ipDispensingLogId || 'newdi'))[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var ipDispensingStore, newLog, log, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDispensingStore = this.props.enrollmentStore.ipDispensingStore;
                        e.preventDefault();
                        e.stopPropagation();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        log = this.getObjectToSave();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, ipDispensingStore.addIpDispensingLog(log)];
                    case 2:
                        newLog = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, ipDispensingStore.updateIpDispensingLog(log)];
                    case 4:
                        newLog = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_2 = _a.sent();
                        this.errorMessage = error_2.message || error_2.detail || error_2;
                        this.hasErrors = this.validateForm(error_2.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newLog) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        _this.getObjectToSave = function () {
            var o = {
                id: _this.ipDispensingLogId,
                ipNumber: parseInt(_this.data.ipNumber.value, 10),
                lotNumber: parseInt(_this.data.lotNumber.value, 10),
                compliance: _this.data.compliance.value !== undefined && _this.data.compliance.value !== ''
                    ? parseInt(_this.data.compliance.value, 10)
                    : undefined,
                dispensedAt: _this.data.dispensedAt.value,
                tabsDispensedCount: parseInt(_this.data.tabsDispensedCount.value, 10),
                dispensingStaffName: _this.data.dispensingStaffName.value,
                tabsReturnedCount: _this.data.tabsReturnedCount.value !== undefined && _this.data.tabsReturnedCount.value !== ''
                    ? parseInt(_this.data.tabsReturnedCount.value, 10)
                    : undefined,
                returnedAt: _this.data.returnedAt.value ? _this.data.returnedAt.value : undefined,
                receivingStaffName: _this.data.receivingStaffName.value
                    ? _this.data.receivingStaffName.value
                    : undefined
            };
            return o;
        };
        return _this;
    }
    Object.defineProperty(NewIpDispensingLog.prototype, "ipDispensingLogId", {
        get: function () {
            var params = this.props.match.params;
            return params.ipDispensingLogId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewIpDispensingLog.prototype, "isEdit", {
        get: function () {
            return !!this.ipDispensingLogId;
        },
        enumerable: false,
        configurable: true
    });
    NewIpDispensingLog.prototype.render = function () {
        var _this = this;
        var patient = this.props.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, "IP log"),
            React.createElement(FlexContainer, { style: { marginTop: '10px', marginBottom: '15px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submit },
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "number", label: "IP number", value: this.data.ipNumber.value, errorText: this.data.ipNumber.error, onChange: function (e) { return _this.fieldChange(e, 'ipNumber'); }, autoFocus: true, name: "ipNumber" }),
                    React.createElement(RdiInput, { type: "number", label: "Lot number", value: this.data.lotNumber.value, errorText: this.data.lotNumber.error, onChange: function (e) { return _this.fieldChange(e, 'lotNumber'); }, name: "lotNumber" })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "date", label: "Dispensed", value: this.data.dispensedAt.value, errorText: this.data.dispensedAt.error, onChange: function (e) { return _this.fieldChange(e, 'dispensedAt'); }, name: "dispensedAt" }),
                    React.createElement(RdiInput, { type: "number", label: "Tabs dispensed", value: this.data.tabsDispensedCount.value, errorText: this.data.tabsDispensedCount.error, onChange: function (e) { return _this.fieldChange(e, 'tabsDispensedCount'); }, name: "tabsDispensedCount" })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "text", label: "Staff dispensing", value: this.data.dispensingStaffName.value, errorText: this.data.dispensingStaffName.error, onChange: function (e) { return _this.fieldChange(e, 'dispensingStaffName'); }, name: "dispensingStaffName" })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "date", label: "Returned", value: this.data.returnedAt.value, errorText: this.data.returnedAt.error, onChange: function (e) { return _this.fieldChange(e, 'returnedAt'); }, name: "returnedAt" }),
                    React.createElement(RdiInput, { type: "number", label: "Tabs returned", value: this.data.tabsReturnedCount.value, errorText: this.data.tabsReturnedCount.error, onChange: function (e) { return _this.fieldChange(e, 'tabsReturnedCount'); }, name: "tabsReturnedCount" })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "text", label: "Staff receiving", value: this.data.receivingStaffName.value, errorText: this.data.receivingStaffName.error, onChange: function (e) { return _this.fieldChange(e, 'receivingStaffName'); }, name: "receivingStaffName" })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "number", label: "Compliance (%)", value: this.data.compliance.value, errorText: this.data.compliance.error, onChange: function (e) { return _this.fieldChange(e, 'compliance'); }, name: "compliance" })),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], NewIpDispensingLog.prototype, "data", void 0);
    __decorate([
        observable
    ], NewIpDispensingLog.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], NewIpDispensingLog.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], NewIpDispensingLog.prototype, "saving", void 0);
    __decorate([
        observable
    ], NewIpDispensingLog.prototype, "loading", void 0);
    __decorate([
        computed
    ], NewIpDispensingLog.prototype, "ipDispensingLogId", null);
    __decorate([
        computed
    ], NewIpDispensingLog.prototype, "isEdit", null);
    NewIpDispensingLog = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectSiteStore,
        observer
    ], NewIpDispensingLog);
    return NewIpDispensingLog;
}(Component));
var InputRow = function (props) { return (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 }, props.children)); };
export default withRouter(NewIpDispensingLog);
