var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import IpDispensingLogService from '~/services/IpDispensingLogService';
var IpDispensingStore = /** @class */ (function () {
    function IpDispensingStore(enrollmentStore) {
        var _this = this;
        this.enrollmentStore = enrollmentStore;
        this._logs = [];
        this._logsCount = 0;
        this.ipDispensingLogsFilters = {
            orderDir: 'DESC',
            orderBy: 'dispensedAt'
        };
        this.ipDispensingLoading = false;
        this.changeIpDispensingFilters = function (newFilters) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ipDispensingLogsFilters = __assign(__assign({}, this.ipDispensingLogsFilters), newFilters);
                        return [4 /*yield*/, this.listIpDispensingLogs()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.listIpDispensingLogs = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, req, _b, _c, _d, e_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        this.ipDispensingLoading = true;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = IpDispensingLogService).listIpDispensingLogs;
                        _d = [siteId,
                            enrollmentId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), this.ipDispensingLogsFilters]))];
                    case 3:
                        req = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        this.ipDispensingLoading = false;
                        errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this._logs = req.items;
                            _this._logsCount = req.count;
                            _this.ipDispensingLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.addIpDispensingLog = function (log) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, toastStore, newLog, _b, _c, _d, e_2;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = IpDispensingLogService).addIpDispensingLog;
                        _d = [log,
                            siteId,
                            enrollmentId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        newLog = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _e.sent();
                        if (e_2.code === 'validation-exception') {
                            throw e_2;
                        }
                        errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this._logs.push(newLog);
                            _this._logsCount += 1;
                        });
                        toastStore.addToast(new ToastMessage("IP log " + log.ipNumber + " created"));
                        return [2 /*return*/, log];
                }
            });
        }); };
        this.updateIpDispensingLog = function (log) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, toastStore, updatedLog, _b, _c, _d, e_3;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = IpDispensingLogService).updatePatientIpDispensingLog;
                        _d = [log,
                            siteId,
                            enrollmentId,
                            log.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updatedLog = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _e.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this._logs.findIndex(function (l) { return l.id === updatedLog.id; });
                            if (existingIndex >= 0) {
                                _this._logs[existingIndex] = updatedLog;
                            }
                            // check also in dosing store, if IP number changed
                            var existingDosingIndex = _this.enrollmentStore.ipDosingStore.log.findIndex(function (l) { return l.ipDispensingLogId === updatedLog.id; });
                            if (existingDosingIndex >= 0) {
                                _this.enrollmentStore.ipDosingStore.log[existingIndex].ipDispensingLog.ipNumber =
                                    updatedLog.ipNumber;
                            }
                        });
                        toastStore.addToast(new ToastMessage("IP log " + updatedLog.ipNumber + " updated"));
                        return [2 /*return*/, updatedLog];
                }
            });
        }); };
        this.deleteIpDispensingLogWithConfirmation = function (log) {
            var confirmationModalStore = _this.enrollmentStore.confirmationModalStore;
            confirmationModalStore.openModal("Do you really want to remove the log entry for " + log.ipNumber + "?", function () { return _this.deleteIpDispensingLog(log); });
        };
        this.deleteIpDispensingLog = function (log) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, toastStore, _b, _c, _d, e_4;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = IpDispensingLogService).deletePatientIpDispensingLog;
                        _d = [siteId,
                            enrollmentId,
                            log.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _e.sent();
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this._logs = _this._logs.filter(function (l) { return l.id !== log.id; });
                            _this._logsCount -= 1;
                        });
                        toastStore.addToast(new ToastMessage("IP log " + log.ipNumber + " removed"));
                        return [2 /*return*/, log];
                }
            });
        }); };
        this.getIpDispensingLogById = function (ipDispensingLogId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, res, _b, _c, _d, e_5;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = IpDispensingLogService).getIpDispensingLogById;
                        _d = [siteId,
                            enrollmentId,
                            ipDispensingLogId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        res = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _e.sent();
                        errorModalStore.setError(e_5);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, res];
                }
            });
        }); };
    }
    Object.defineProperty(IpDispensingStore.prototype, "logs", {
        get: function () {
            return this._logs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IpDispensingStore.prototype, "logsCount", {
        get: function () {
            return this._logsCount;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], IpDispensingStore.prototype, "_logs", void 0);
    __decorate([
        observable
    ], IpDispensingStore.prototype, "_logsCount", void 0);
    __decorate([
        observable
    ], IpDispensingStore.prototype, "ipDispensingLogsFilters", void 0);
    __decorate([
        observable
    ], IpDispensingStore.prototype, "ipDispensingLoading", void 0);
    return IpDispensingStore;
}());
export { IpDispensingStore };
export var injectIpDispensingStore = inject('ipDispensingStore');
