import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import React from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
import { getPatientFeedbackHistoryRoute } from '~/routing/Routes';
var PatientFeedbackCard = function (props) {
    var enrollment = props.enrollment, siteId = props.siteId, trialId = props.trialId, enrollmentId = props.enrollmentId, patientId = props.patientId;
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, "PATIENT FEEDBACK"),
            React.createElement(RdiLink, { to: getPatientFeedbackHistoryRoute(siteId, patientId, trialId, enrollmentId), xs: true }, "View history")),
        React.createElement("div", null,
            !!enrollment.feedbackAverageRating && (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(RdiTypography, { color: TypographyColorsEnum.TrueBlack, variant: "h1", style: { marginBottom: '0px' } }, enrollment.feedbackAverageRating),
                React.createElement(RdiTypography, { variant: "body2" }, "of 5"))),
            !enrollment.feedbackAverageRating && (React.createElement(RdiTypography, { style: { marginBottom: '0px' }, variant: "h1" }, "No feedback"))),
        React.createElement(RdiTypography, { variant: "caption", style: { color: ColorsEnum.BlackLightToMedium } }, enrollment.feedbackLastUpdatedString)));
};
export default PatientFeedbackCard;
