var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTreeView from '@cros/shared/components/misc/RdiTreeView';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { CircularProgress, createStyles, withStyles } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import config from '~/config';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectDashboardFilesStore } from '../../../../stores/DashboardFilesStore';
import ShareModal from '../dashboard-files/ShareModal';
var styles = function (theme) {
    var _a;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a)
    });
};
var ClinicDocumentsPage = /** @class */ (function (_super) {
    __extends(ClinicDocumentsPage, _super);
    function ClinicDocumentsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasShowModal = false;
        _this.uploadFile = function (files) {
            var dashboardFilesStore = _this.props.dashboardFilesStore;
            if (files === null || files === void 0 ? void 0 : files.length) {
                Promise.all(files.map(function (f) {
                    return dashboardFilesStore.uploadFile(new UploadedFile({
                        originalname: f.path.charAt(0) === '/' ? f.path.substring(1) : f.path,
                        mimetype: f.type,
                        size: f.size,
                        url: null,
                        file: f
                    }));
                }));
            }
        };
        _this.handleCreateFolder = function (folderName, parentFolder) {
            var _a;
            var addEmptyFolder = _this.props.dashboardFilesStore.addEmptyFolder;
            var newFile = new UploadedFile(__assign(__assign({}, parentFolder.file), { url: 'folder' }));
            var targetFileNameSplit = ((_a = newFile.originalname) === null || _a === void 0 ? void 0 : _a.split('/')) || [''];
            if (parentFolder.isFolder || parentFolder.file.url === 'folder') {
                newFile.originalname = "" + (parentFolder.fullname ? parentFolder.fullname + "/" : '') + folderName;
            }
            else {
                targetFileNameSplit[targetFileNameSplit.length - 1] = folderName;
                newFile.originalname = targetFileNameSplit.join('/');
            }
            addEmptyFolder(newFile);
        };
        _this.handleRename = function (node) { return __awaiter(_this, void 0, void 0, function () {
            var updateFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateFile = this.props.dashboardFilesStore.updateFile;
                        return [4 /*yield*/, updateFile(node.file)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleDelete = function (node) {
            var deleteFileWithConfirmation = _this.props.dashboardFilesStore.deleteFileWithConfirmation;
            deleteFileWithConfirmation(node);
        };
        _this.handleShareFile = function (node) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.currentFile = node.file;
                this.hasShowModal = true;
                return [2 /*return*/];
            });
        }); };
        _this.onModalClose = function () {
            _this.hasShowModal = !_this.hasShowModal;
        };
        _this.getFileSharedPatientsList = function (fileId) { return __awaiter(_this, void 0, void 0, function () {
            var getFileSharedPatientsList;
            return __generator(this, function (_a) {
                getFileSharedPatientsList = this.props.dashboardFilesStore.getFileSharedPatientsList;
                return [2 /*return*/, getFileSharedPatientsList(fileId)];
            });
        }); };
        _this.hasPermissions = function (permissions) {
            var _a;
            var _b = _this.props, accountStore = _b.accountStore, siteStore = _b.siteStore;
            return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId);
        };
        return _this;
    }
    ClinicDocumentsPage.prototype.componentDidMount = function () {
        var getDashboardFiles = this.props.dashboardFilesStore.getDashboardFiles;
        getDashboardFiles();
    };
    ClinicDocumentsPage.prototype.render = function () {
        var _a, _b;
        var _c = this.props, accountStore = _c.accountStore, siteStore = _c.siteStore, classes = _c.classes, _d = _c.dashboardFilesStore, dashboardFiles = _d.dashboardFiles, isLoading = _d.isLoading;
        if (!this.hasPermissions([{ aclOp: AclOpEnum.CREATE, aclModule: AclModuleEnum.CLINIC_DOCUMENTS }])) {
            return null;
        }
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Clinic documents"),
            React.createElement(FlexContainer, { alignItems: "center", flexDirection: "column", margin: 30, padding: "5px", permissions: [
                    {
                        aclOp: AclOpEnum.CREATE,
                        aclModule: AclModuleEnum.CLINIC_DOCUMENTS
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                React.createElement(DropzoneArea, { onDrop: this.uploadFile, showPreviewsInDropzone: false, maxFileSize: 104857600, filesLimit: 100, showAlerts: false }),
                isLoading && React.createElement(CircularProgress, null)),
            !!(dashboardFiles === null || dashboardFiles === void 0 ? void 0 : dashboardFiles.length) && (React.createElement(RdiTreeView, { apiUrl: config.apiUrl, files: dashboardFiles, patientsList: siteStore === null || siteStore === void 0 ? void 0 : siteStore.patients, showCreateFolder: this.hasPermissions([
                    { aclOp: AclOpEnum.CREATE, aclModule: AclModuleEnum.CLINIC_DOCUMENTS }
                ]), showDelete: this.hasPermissions([
                    { aclOp: AclOpEnum.DELETE, aclModule: AclModuleEnum.CLINIC_DOCUMENTS }
                ]), showRename: this.hasPermissions([
                    { aclOp: AclOpEnum.UPDATE, aclModule: AclModuleEnum.CLINIC_DOCUMENTS }
                ]), showShare: this.hasPermissions([
                    { aclOp: AclOpEnum.CREATE, aclModule: AclModuleEnum.CLINIC_DOCUMENTS }
                ]), createFolder: this.handleCreateFolder, deleteFile: this.handleDelete, renameFile: this.handleRename, shareFile: this.handleShareFile })),
            React.createElement(ShareModal, { fileName: (_a = this.currentFile) === null || _a === void 0 ? void 0 : _a.originalname, fileId: (_b = this.currentFile) === null || _b === void 0 ? void 0 : _b.id, hasShowModal: this.hasShowModal, onClose: this.onModalClose })));
    };
    __decorate([
        observable
    ], ClinicDocumentsPage.prototype, "hasShowModal", void 0);
    __decorate([
        observable
    ], ClinicDocumentsPage.prototype, "currentFile", void 0);
    ClinicDocumentsPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectDashboardFilesStore,
        observer
    ], ClinicDocumentsPage);
    return ClinicDocumentsPage;
}(Component));
export default withStyles(styles)(withRouter(ClinicDocumentsPage));
