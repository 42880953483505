var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CognitoStore from '@cros/shared/stores/CognitoStore';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import AccountService from '~/services/AccountService';
var AccountStore = /** @class */ (function () {
    function AccountStore(errorModalStore, toastStore, confirmationModalStore) {
        var _this = this;
        this.errorModalStore = errorModalStore;
        this.toastStore = toastStore;
        this.confirmationModalStore = confirmationModalStore;
        this.Init = function () { return __awaiter(_this, void 0, Promise, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.Init()];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            this.getAccountDetails();
                        }
                        return [2 /*return*/, success];
                }
            });
        }); };
        this.changePassword = function (oldPassword, newPassword) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.changePassword(oldPassword, newPassword)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.resetPassword = function (email) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.resetPassword(email)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.setNewPassword = function (username, code, password) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.setNewPassword(username, code, password)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.login = function (username, password) { return __awaiter(_this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.login(username, password)];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            this.getAccountDetails();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.logout = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        _b = (_a = AccountService).logout;
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, [_c.sent()])];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _c.sent();
                        this.errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 4: return [4 /*yield*/, this.cognitoStore.logout()];
                    case 5:
                        _c.sent();
                        this._accountDetail = null;
                        return [2 /*return*/];
                }
            });
        }); };
        this.completeNewPassword = function (newPassword) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cognitoStore.completeNewPassword(newPassword)];
                    case 1:
                        _a.sent();
                        this.getAccountDetails();
                        return [2 /*return*/];
                }
            });
        }); };
        this.authToken = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.cognitoStore.authToken()];
            });
        }); };
        this.getAccountDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _a = this;
                        _c = (_b = AccountService).getAccountDetail;
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _c.apply(_b, [_d.sent()])];
                    case 2:
                        _a._accountDetail = _d.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.sendPhoneConfirmation = function (secret) { return __awaiter(_this, void 0, Promise, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateUserContact({
                            secret: secret
                        })];
                    case 1:
                        user = _a.sent();
                        if (user) {
                            this.toastStore.addToast(new ToastMessage("SMS notifications enabled"));
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        }); };
        this.disableNotificationsWithConfirmation = function (onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to disable notifications?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.disableNotifications()];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.enableNotificationsWithConfirmation = function (onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to enable notifications?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.enableNotifications()];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.disableNotifications = function () { return __awaiter(_this, void 0, Promise, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateUserContact({
                            phoneNumber: this.accountDetail.phoneNumber,
                            phoneNumberPrefix: this.accountDetail.phoneNumberPrefix,
                            phoneNumberForSMS: false
                        })];
                    case 1:
                        user = _a.sent();
                        if (user) {
                            this.toastStore.addToast(new ToastMessage("SMS notifications disabled"));
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        }); };
        this.enableNotifications = function () { return __awaiter(_this, void 0, Promise, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateUserContact({
                            phoneNumber: this.accountDetail.phoneNumber,
                            phoneNumberPrefix: this.accountDetail.phoneNumberPrefix,
                            phoneNumberForSMS: true
                        })];
                    case 1:
                        user = _a.sent();
                        if (user) {
                            this.toastStore.addToast(new ToastMessage("SMS notifications enabled"));
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        }); };
        this.updateUserContact = function (user) { return __awaiter(_this, void 0, Promise, function () {
            var resp, _a, _b, _c, e_3;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _b = (_a = AccountService).updateUserContact;
                        _c = [user];
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        resp = _d.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _d.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 4:
                        runInAction(function () {
                            _this._accountDetail = resp;
                        });
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.deletePhoneNumberWithConfirmation = function (onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to change your phone number? You will have to verify it again.", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deletePhoneNumber()];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.deletePhoneNumber = function () { return __awaiter(_this, void 0, void 0, function () {
            var user, _a, _b, _c, e_4;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _b = (_a = AccountService).updateUserContact;
                        _c = [{ phoneNumber: null, phoneNumberPrefix: null }];
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        user = _d.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _d.sent();
                        this.errorModalStore.setError(e_4);
                        return [2 /*return*/];
                    case 4:
                        runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this._accountDetail = user;
                                return [2 /*return*/];
                            });
                        }); });
                        this.toastStore.addToast(new ToastMessage("Phone number removed"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.cognitoStore = new CognitoStore(errorModalStore, toastStore, confirmationModalStore, config);
    }
    Object.defineProperty(AccountStore.prototype, "LoginState", {
        get: function () {
            if (!this.accountDetail && this.cognitoStore.LoginState === 'LOGGED_IN') {
                return 'LOADING';
            }
            return this.cognitoStore.LoginState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "confirmPasswordChangeParams", {
        get: function () {
            return this.cognitoStore.confirmPasswordChangeParams;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "cognitoUser", {
        get: function () {
            return this.cognitoStore.cognitoUser;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "accountDetail", {
        get: function () {
            return this._accountDetail;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "accountType", {
        get: function () {
            return this._accountDetail && this._accountDetail.company && this._accountDetail.company.type
                ? this._accountDetail.company.type
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "fullName", {
        get: function () {
            var _a;
            return "" + ((_a = this._accountDetail) === null || _a === void 0 ? void 0 : _a.fullName);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountStore.prototype, "companyId", {
        get: function () {
            return this._accountDetail.company ? this._accountDetail.company.id : undefined;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], AccountStore.prototype, "LoginState", null);
    __decorate([
        computed
    ], AccountStore.prototype, "confirmPasswordChangeParams", null);
    __decorate([
        computed
    ], AccountStore.prototype, "cognitoUser", null);
    __decorate([
        observable
    ], AccountStore.prototype, "_accountDetail", void 0);
    __decorate([
        computed
    ], AccountStore.prototype, "accountDetail", null);
    __decorate([
        computed
    ], AccountStore.prototype, "accountType", null);
    __decorate([
        computed
    ], AccountStore.prototype, "fullName", null);
    __decorate([
        computed
    ], AccountStore.prototype, "companyId", null);
    return AccountStore;
}());
export default AccountStore;
export var injectAccountStore = inject('accountStore');
