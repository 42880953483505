var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import DateWithIcon from '@cros/shared/components/misc/DateWithIcon';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import ProgressWithStatus from '@cros/shared/components/misc/ProgressWithStatus';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { EnrollmentStatusEnum, EnrollmentStatusLabels } from '@cros/shared/constants/enums/EnrollmentStatusEnum';
import { formatDate, formatDatetime } from '@cros/shared/utils/dateUtils';
import { AppBar, CircularProgress, createStyles, Tab, TableBody, TableCell, TableHead, TableRow, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getBillingCodesRoute, getNewPatientRoute, getPatientRoute, getProtocolRoute } from '~/routing/Routes';
import CheckMarkIcon from '~/static/CheckMarkIcon.svg';
import InProgressSmallBlack from '~/static/InProgressSmallBlack.svg';
import InvitedArrow from '~/static/InvitedArrow.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTrialStore } from '~/stores/TrialStore';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        menuContainer: (_b = {
                marginBottom: '20px',
                alignItems: 'center',
                marginTop: '20px',
                flexWrap: 'wrap'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _b)
    });
};
var EnrollmentListPage = /** @class */ (function (_super) {
    __extends(EnrollmentListPage, _super);
    function EnrollmentListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'fullName',
                label: 'Patient',
                sortable: true,
                sortName: 'patient__firstName',
                width: '20%'
            },
            {
                id: 'code',
                label: 'ID',
                sortable: true,
                sortName: 'code',
                width: '12%'
            },
            {
                id: 'status',
                label: 'Status',
                sortable: true,
                sortName: 'status',
                width: '18%'
            },
            {
                id: 'trialPercent',
                label: 'Trial progress',
                sortable: true,
                sortName: 'progress',
                width: '15%'
            },
            {
                id: 'nextVisitMoment',
                sortable: true,
                sortName: 'nextVisit__engagement',
                label: 'Next visit',
                width: '30%'
            }
        ];
        _this.tabIndex = 'all';
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchTrialPatients()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.fetchTrialPatients = function () { return __awaiter(_this, void 0, void 0, function () {
            var getEnrollments;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        getEnrollments = this.props.trialStore.getEnrollments;
                        return [4 /*yield*/, getEnrollments()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.changeTab = function (event, value) { return __awaiter(_this, void 0, void 0, function () {
            var trialStore;
            return __generator(this, function (_a) {
                trialStore = this.props.trialStore;
                if (this.tabIndex !== value) {
                    this.tabIndex = value;
                    trialStore.changeEnrollmentFilters({
                        page: 1,
                        status: value !== 'all' ? value : null
                    });
                }
                return [2 /*return*/];
            });
        }); };
        _this.changeSearchString = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var trialStore;
            return __generator(this, function (_a) {
                trialStore = this.props.trialStore;
                trialStore.changeEnrollmentFilters({
                    page: 1,
                    search: e.value
                });
                return [2 /*return*/];
            });
        }); };
        _this.formatStatus = function (patient) {
            var _a;
            var nextVisit = patient.nextVisit;
            var trialStatus = patient.status;
            return (React.createElement(FlexContainer, { alignItems: "center" },
                React.createElement("div", { style: { marginRight: '9px' } },
                    trialStatus === EnrollmentStatusEnum.INVITED && React.createElement("img", { alt: "img", src: InvitedArrow }),
                    trialStatus === EnrollmentStatusEnum.IN_PROGRESS && (React.createElement("img", { alt: "img", src: InProgressSmallBlack })),
                    trialStatus === EnrollmentStatusEnum.COMPLETED && (React.createElement("img", { alt: "checkmark", src: CheckMarkIcon }))),
                trialStatus === EnrollmentStatusEnum.INVITED && (React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.BlackMedium },
                    "Invited ",
                    formatDate(patient.invitationDate))),
                trialStatus === EnrollmentStatusEnum.IN_PROGRESS && React.createElement(React.Fragment, null, (_a = nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.stage) === null || _a === void 0 ? void 0 : _a.name),
                trialStatus === EnrollmentStatusEnum.COMPLETED && (React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Success },
                    "Completed ",
                    formatDatetime(patient.trialFinishedAt)))));
        };
        return _this;
    }
    EnrollmentListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteId = _a.match.params.siteId, _b = _a.trialStore, enrollmentStatusCounts = _b.enrollmentStatusCounts, enrollments = _b.enrollments, enrollmentsCount = _b.enrollmentsCount, changeEnrollmentFilters = _b.changeEnrollmentFilters, enrollmentsLoading = _b.enrollmentsLoading, patientFilters = _b.patientFilters, trialId = _b.trialId, trial = _b.trial, siteStore = _a.siteStore, accountStore = _a.accountStore, classes = _a.classes;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, trial === null || trial === void 0 ? void 0 : trial.name),
            React.createElement("div", null,
                React.createElement(RdiLine, { md: true }),
                React.createElement(FlexContainer, { margin: 25, className: classes.menuContainer },
                    React.createElement(RdiLink, { to: "" + getProtocolRoute(siteId, trialId), permissions: [
                            {
                                aclOp: AclOpEnum.READ,
                                aclModule: AclModuleEnum.PROTOCOL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "View protocol"),
                    React.createElement(RdiLink, { to: "" + getBillingCodesRoute(siteId, trialId), permissions: [
                            {
                                aclOp: AclOpEnum.READ,
                                aclModule: AclModuleEnum.PROTOCOL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Billing codes")),
                React.createElement(RdiLine, { md: true }),
                React.createElement(FlexContainer, { style: {
                        marginBottom: '20px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '30px',
                        flexWrap: 'wrap'
                    } },
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", placeholder: "Search", label: " ", value: patientFilters.search, onChange: this.changeSearchString }),
                    React.createElement(RdiButton, { name: "new-patient", fullWidth: false, to: getNewPatientRoute(siteId) + "?from=" + trialId, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.PATIENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "New patient")),
                React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: this.tabIndex, onChange: this.changeTab, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "patients-tabs" }, __spreadArrays(['all'], Object.values(EnrollmentStatusEnum)).map(function (enumType) { return (React.createElement(Tab, { key: enumType, value: enumType, label: (EnrollmentStatusLabels[enumType] || 'All') + " (" + (enrollmentStatusCounts[enumType] ? enrollmentStatusCounts[enumType] : 0) + ")" })); }))),
                React.createElement(RdiTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeEnrollmentFilters, filters: patientFilters })); }))),
                    !enrollmentsLoading && (React.createElement(TableBody, null,
                        enrollments.map(function (enrollment, index) {
                            var _a, _b, _c;
                            return (React.createElement(TableRow, { hover: true, tabIndex: -1, key: index },
                                React.createElement(TableCell, null,
                                    React.createElement(RdiLink, { to: getPatientRoute(siteId, (_a = enrollment.patient) === null || _a === void 0 ? void 0 : _a.id, trialId, enrollment.id) }, (_b = enrollment.patient) === null || _b === void 0 ? void 0 : _b.fullName)),
                                React.createElement(TableCell, null, enrollment.code),
                                React.createElement(TableCell, null, _this.formatStatus(enrollment)),
                                React.createElement(TableCell, null,
                                    React.createElement(ProgressWithStatus, { value: enrollment.progress })),
                                React.createElement(TableCell, null, enrollment.nextVisit ? (React.createElement(DateWithIcon, { displayType: "humanized date and time", value: (_c = enrollment.nextVisit) === null || _c === void 0 ? void 0 : _c.engagement })) : (React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Placeholder }, "No visit scheduled")))));
                        }),
                        !enrollmentsLoading && enrollmentsCount === 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 5 }, "No results")))))),
                enrollmentsLoading && (React.createElement(FlexContainer, { style: { marginTop: '45px' }, alignItems: "center", justifyContent: "center" },
                    React.createElement(CircularProgress, null))),
                !enrollmentsLoading && (React.createElement(RdiTablePagination, { count: enrollmentsCount, rowsPerPage: patientFilters.rowsPerPage, page: patientFilters.page, onChangePage: function (e, newPage) { return changeEnrollmentFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changeEnrollmentFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } })))));
    };
    __decorate([
        observable
    ], EnrollmentListPage.prototype, "tabIndex", void 0);
    EnrollmentListPage = __decorate([
        injectTrialStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], EnrollmentListPage);
    return EnrollmentListPage;
}(Component));
export default withStyles(styles)(withRouter(EnrollmentListPage));
