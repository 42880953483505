var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import PatientService from '~/services/PatientService';
import SiteService from '../services/SiteService';
import TrialService from '../services/TrialService';
var SiteStore = /** @class */ (function () {
    function SiteStore(accountStore) {
        var _this = this;
        this.accountStore = accountStore;
        this.siteLoading = true;
        this.trialsLoading = true;
        this.trials = [];
        this.patients = [];
        this.authToken = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.accountStore.authToken()];
            });
        }); };
        this.InitSite = function (siteId) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, _a, _b, _c, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.siteLoading = true;
                        authToken = this.accountStore.authToken;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _a = this;
                        _c = (_b = SiteService).getSiteInfo;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, [_d.sent(), siteId])];
                    case 3:
                        _a.siteInfo = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.errorModalStore.setError(e_1);
                        this.siteLoading = false;
                        this.trialsLoading = false;
                        return [2 /*return*/];
                    case 5:
                        this.siteLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.getBillingItemsCount = function (siteId) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, _a, _b, _c, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _a = this.siteInfo.counts;
                        _c = (_b = SiteService).getBillingItemsCount;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, [_d.sent(), siteId])];
                    case 3:
                        _a.billedItemsNeedsApprovalCount = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.clear = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.siteInfo = null;
                this.trials = [];
                this.patients = [];
                return [2 /*return*/];
            });
        }); };
        this.listTrials = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, trials, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        // in case site info call failed, don't continue
                        if (!this.siteInfo) {
                            this.trialsLoading = false;
                            return [2 /*return*/];
                        }
                        this.trialsLoading = true;
                        authToken = this.accountStore.authToken;
                        _b = (_a = TrialService).listTrials;
                        return [4 /*yield*/, authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, [_c.sent(), this.siteId])];
                    case 2:
                        trials = _c.sent();
                        this.trials = trials;
                        this.trialsLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.invitePatient = function (invite) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, _a, _b, _c, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = SiteService).invitePatient;
                        _c = [this.siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent(), invite]))];
                    case 3:
                        _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/, false];
                    case 5:
                        this.toastStore.addToast(new ToastMessage("Invite to " + invite.firstName + " " + invite.lastName + " has been sent."));
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.listVisitDefinitions = function (whereParams) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, _c, e_4;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = SiteService).listVisitDefinitions;
                        _c = [this.siteId, whereParams];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        res = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _d.sent();
                        this.errorModalStore.setError(e_4);
                        return [2 /*return*/, []];
                    case 5: return [2 /*return*/, res];
                }
            });
        }); };
        this.getPatients = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, e_5;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _b = (_a = PatientService).listPatients;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent(), this.siteId, {
                                rowsPerPage: 10,
                                orderBy: 'createdAt',
                                orderDir: 'DESC',
                                hasNextVisit: true
                            }])];
                    case 3:
                        res = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _c.sent();
                        this.patients = [];
                        this.errorModalStore.setError(e_5);
                        return [2 /*return*/, false];
                    case 5:
                        this.patients = res.items;
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.updateSite = function (site) { return __awaiter(_this, void 0, Promise, function () {
            var updated, _a, _b, _c, e_6;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _b = (_a = SiteService).updateSite;
                        _c = [site, this.siteId];
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        updated = _d.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_6 = _d.sent();
                        if (e_6.code === 'validation-exception') {
                            throw e_6;
                        }
                        this.errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 4:
                        runInAction(function () {
                            var oldCounts = _this.siteInfo.counts;
                            _this.siteInfo = updated;
                            _this.siteInfo.counts = oldCounts;
                        });
                        this.toastStore.addToast(new ToastMessage("Site " + site.name + " updated"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
    }
    Object.defineProperty(SiteStore.prototype, "firstTrial", {
        // first trial
        get: function () {
            if (this.trials.length > 0) {
                return this.trials[0];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "firstTrialId", {
        // first trial id
        get: function () {
            if (this.trials.length > 0) {
                return this.trials[0].id;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "firstTrialCounts", {
        // first trial counts
        get: function () {
            if (this.trials.length > 0) {
                return this.trials[0].trialCounts;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "siteId", {
        get: function () {
            return this.siteInfo ? this.siteInfo.id : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "siteName", {
        get: function () {
            return this.siteInfo ? this.siteInfo.name : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "toastStore", {
        get: function () {
            return this.accountStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.accountStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SiteStore.prototype, "errorModalStore", {
        get: function () {
            return this.accountStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], SiteStore.prototype, "siteLoading", void 0);
    __decorate([
        observable
    ], SiteStore.prototype, "trialsLoading", void 0);
    __decorate([
        observable
    ], SiteStore.prototype, "siteInfo", void 0);
    __decorate([
        observable
    ], SiteStore.prototype, "trials", void 0);
    __decorate([
        observable
    ], SiteStore.prototype, "patients", void 0);
    __decorate([
        computed
    ], SiteStore.prototype, "firstTrial", null);
    __decorate([
        computed
    ], SiteStore.prototype, "firstTrialId", null);
    __decorate([
        computed
    ], SiteStore.prototype, "firstTrialCounts", null);
    __decorate([
        computed
    ], SiteStore.prototype, "siteId", null);
    __decorate([
        computed
    ], SiteStore.prototype, "siteName", null);
    __decorate([
        computed
    ], SiteStore.prototype, "toastStore", null);
    __decorate([
        computed
    ], SiteStore.prototype, "confirmationModalStore", null);
    __decorate([
        computed
    ], SiteStore.prototype, "errorModalStore", null);
    return SiteStore;
}());
export default SiteStore;
export var injectSiteStore = inject('siteStore');
