import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { EnrollmentStatusEnum } from '@cros/shared/constants/enums/EnrollmentStatusEnum';
import { formatDate, formatDatetime } from '@cros/shared/utils/dateUtils';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
import CheckMarkIcon from '~/static/CheckMarkIcon.svg';
import InProgressIcon from '~/static/InProgressIcon.svg';
import InvitedArrow from '~/static/InvitedArrow.svg';
var TrialProgressCard = function (props) {
    var _a;
    var _b = props.enrollment, status = _b.status, progress = _b.progress, invitationDate = _b.invitationDate, nextVisit = _b.nextVisit, trialFinishedAt = _b.trialFinishedAt;
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, "TRIAL PROGRESS")),
        React.createElement("div", { style: { width: '100%' } },
            React.createElement(RdiTypography, { style: { marginBottom: '6px' }, variant: "h1" }, progress + "%"),
            React.createElement(LinearProgress, { color: progress === 100 ? 'secondary' : 'primary', value: progress, variant: "determinate", style: { height: '8px' } })),
        React.createElement("div", null,
            React.createElement(FlexContainer, { alignItems: "center" },
                status === EnrollmentStatusEnum.INVITED && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginRight: '7px' } },
                        React.createElement("img", { alt: "img", src: InvitedArrow })),
                    React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, "Invited " + (invitationDate ? formatDate(invitationDate) : '')))),
                status === EnrollmentStatusEnum.IN_PROGRESS && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginRight: '7px' } },
                        React.createElement("img", { alt: "in-progress", src: InProgressIcon })),
                    React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, ((_a = nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.stage) === null || _a === void 0 ? void 0 : _a.name) || 'In progress'))),
                status === EnrollmentStatusEnum.COMPLETED && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginRight: '7px' } },
                        React.createElement("img", { alt: "checkmark", src: CheckMarkIcon })),
                    React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Success },
                        "Completed on: ",
                        trialFinishedAt ? formatDatetime(trialFinishedAt) : 'unknown')))))));
};
export default TrialProgressCard;
