var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { assignFilterLabels } from '@cros/shared/utils/queryStringUtils';
import debounce from 'lodash/debounce';
import { action, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import PatientService from '~/services/PatientService';
var PatientListStore = /** @class */ (function () {
    function PatientListStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.patients = [];
        this.patientsCount = 0;
        this.patientFilters = {
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderBy: 'createdAt',
            orderDir: 'DESC',
            search: undefined,
            trialId: undefined
        };
        this.patientFilterLabels = {
            trialId: 'All'
        };
        this.patientsLoading = false;
        this.trialLoading = false;
        this.changePatientFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.patientFilters = __assign(__assign({}, this.patientFilters), newFilters);
                        this.patientFilterLabels = __assign(__assign({}, this.patientFilterLabels), newLabels);
                        if (!!('search' in newFilters)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getPatients()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.searchDebounce.cancel();
                        this.searchDebounce();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getPatients = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, e_1;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        this.patientsLoading = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _b = (_a = PatientService).listPatients;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent(), this.siteStore.siteId,
                            this.patientFilters])];
                    case 3:
                        res = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _c.sent();
                        this.patients = [];
                        this.patientsCount = 0;
                        this.errorModalStore.setError(e_1);
                        this.patientsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.patients = res.items;
                            _this.patientsCount = res.totalItems;
                            _this.patientsLoading = false;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.deletePatientWithConfirmation = function (patient, onSuccess) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete patient " + patient.fullName + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deletePatient(patient)];
                            case 1:
                                _a.sent();
                                if (onSuccess)
                                    onSuccess();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.deletePatient = function (patient) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, _a, _b, _c, e_2;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PatientService).deletePatient;
                        _c = [siteId, patient.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.patients.findIndex(function (ev) { return ev.id === patient.id; });
                            if (existingIndex >= 0) {
                                _this.patients.splice(existingIndex, 1);
                            }
                            _this.patientsCount -= 1;
                        });
                        this.toastStore.addToast(new ToastMessage("Patient " + patient.fullName + " deleted"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.getPatientFilters = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PatientService).getPatientFilters;
                        _c = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 5:
                        this.availableFilters = resp;
                        assignFilterLabels(this.patientFilters, this.patientFilterLabels, this.availableFilters);
                        return [2 /*return*/];
                }
            });
        }); };
        this.searchDebounce = debounce(this.getPatients, autoSaveConfig.autoSaveDelay);
    }
    Object.defineProperty(PatientListStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientListStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientListStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientListStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], PatientListStore.prototype, "patients", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "patientsCount", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "availableFilters", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "patientFilters", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "patientFilterLabels", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "patientsLoading", void 0);
    __decorate([
        observable
    ], PatientListStore.prototype, "trialLoading", void 0);
    __decorate([
        action
    ], PatientListStore.prototype, "getPatients", void 0);
    return PatientListStore;
}());
export default PatientListStore;
export var injectPatientListStore = inject('PatientListStore');
