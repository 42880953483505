var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import NavigationModal from '@cros/shared/components/modals/NavigationModal';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import InsuranceListPage from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/InsuranceListPage';
import PatientDetailNavigation from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/PatientDetailNavigation';
import UserDetails from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/PatientDetails';
import PatientEnrollmentsPage from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/PatientEnrollmentsPage';
import PhoneInfoPage from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/PhoneInfoPage';
import { getAdverseEventsRoute, getDemographicsRoute, getEnrollmentsRoute, getInsurancesRoute, getMedicalHistoryRoute, getMedicalRecordsRoute, getMedicationsRoute, getPatientRoute, getPhoneInfoRoute, getUserDetailsRoute, getPatientSharedFilesRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
import AdverseEvents from './AdverseEventsPage';
import DemographicsPage from './DemographicsPage';
import MedicalHistoryPage from './MedicalHistoryPage';
import MedicalRecordsPage from './MedicalRecordsPage';
import MedicationListPage from './MedicationListPage';
import PatientSharedFiles from './PatientSharedFiles';
var styles = function () {
    return createStyles({
        activeProcedureLink: {
            backgroundColor: "" + ColorsEnum.Primary,
            '&:hover': {
                backgroundColor: "rgba(70,170,200,0.85)"
            },
            '&.inverted:hover': {
                color: ColorsEnum.BlueDark + "!important",
                backgroundColor: "white!important",
                border: "2px solid " + ColorsEnum.BlueDark + "!important"
            },
            '& span': {
                color: ColorsEnum.White + " !important"
            }
        }
    });
};
var PatientDetailModal = /** @class */ (function (_super) {
    __extends(PatientDetailModal, _super);
    function PatientDetailModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tabs = [
            {
                id: 'userdetails',
                label: 'Patient details',
                url: getUserDetailsRoute,
                isIndex: true
            },
            {
                id: 'insurances',
                label: 'Insurances',
                url: getInsurancesRoute
            },
            {
                id: 'demographics',
                label: 'Demographics',
                url: getDemographicsRoute
            },
            {
                id: 'medicalhistory',
                label: 'Medical history',
                url: getMedicalHistoryRoute
            },
            {
                id: 'medicalrecords',
                label: 'Medical records',
                url: getMedicalRecordsRoute
            },
            {
                id: 'medication',
                label: 'Medication',
                url: getMedicationsRoute
            },
            {
                id: 'adverseevents',
                label: 'Adverse events',
                url: getAdverseEventsRoute
            },
            {
                id: 'trials',
                label: 'Trials',
                url: getEnrollmentsRoute
            },
            {
                id: 'phoneInfo',
                label: 'Smartphone info',
                url: getPhoneInfoRoute
            },
            {
                id: 'sharedfiles',
                label: 'Shared files',
                url: getPatientSharedFilesRoute
            }
        ];
        _this.onClose = function () {
            var _a = _this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, history = _a.history;
            history.push(getPatientRoute(siteId, patientId, trialId, enrollmentId));
        };
        return _this;
    }
    PatientDetailModal.prototype.render = function () {
        var _a = this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, patient = _a.patientStore.patient;
        return (React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: SiteRoutes.PATIENT_DETAIL_MODAL_ROUTE },
                React.createElement(Redirect, { to: "" + getUserDetailsRoute(siteId, patientId, trialId, enrollmentId) })),
            React.createElement(NavigationModal, { onClose: this.onClose, NavigationDrawer: PatientDetailNavigation, drawerProps: {
                    patient: patient
                } },
                React.createElement(Switch, null,
                    React.createElement(Route, { path: SiteRoutes.USER_DETAILS_ROUTE },
                        React.createElement(UserDetails, null)),
                    React.createElement(Route, { path: SiteRoutes.INSURANCES_ROUTE },
                        React.createElement(InsuranceListPage, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.DEMOGRAPHICS_ROUTE },
                        React.createElement(DemographicsPage, null)),
                    React.createElement(Route, { path: SiteRoutes.MEDICAL_HISTORY_ROUTE },
                        React.createElement(MedicalHistoryPage, null)),
                    React.createElement(Route, { path: SiteRoutes.MEDICAL_RECORDS_ROUTE },
                        React.createElement(MedicalRecordsPage, null)),
                    React.createElement(Route, { path: SiteRoutes.MEDICATIONS_ROUTE },
                        React.createElement(MedicationListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.ADVERSE_EVENTS_ROUTE },
                        React.createElement(AdverseEvents, null)),
                    React.createElement(Route, { path: SiteRoutes.ENROLLMENTS_ROUTE },
                        React.createElement(PatientEnrollmentsPage, null)),
                    React.createElement(Route, { path: SiteRoutes.PHONE_INFO_ROUTE },
                        React.createElement(PhoneInfoPage, null)),
                    React.createElement(Route, { path: SiteRoutes.PATIENT_SHARED_FILES },
                        React.createElement(PatientSharedFiles, null))))));
    };
    PatientDetailModal = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectPatientStore,
        observer
    ], PatientDetailModal);
    return PatientDetailModal;
}(Component));
export default withStyles(styles)(withRouter(PatientDetailModal));
