export var EnrollmentStatusEnum;
(function (EnrollmentStatusEnum) {
    EnrollmentStatusEnum["INVITED"] = "invited";
    EnrollmentStatusEnum["IN_PROGRESS"] = "in-progress";
    EnrollmentStatusEnum["COMPLETED"] = "completed";
    EnrollmentStatusEnum["EARLY_TERMINATION"] = "early-termination";
})(EnrollmentStatusEnum || (EnrollmentStatusEnum = {}));
export var EnrollmentStatusLabels;
(function (EnrollmentStatusLabels) {
    EnrollmentStatusLabels["invited"] = "Invited";
    EnrollmentStatusLabels["in-progress"] = "In progress";
    EnrollmentStatusLabels["completed"] = "Completed";
    EnrollmentStatusLabels["early-termination"] = "Early termination";
})(EnrollmentStatusLabels || (EnrollmentStatusLabels = {}));
