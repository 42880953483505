var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { assignFiltersFromQueryString, getURLSearchObject } from '@cros/shared/utils/queryStringUtils';
import { observable, runInAction } from 'mobx';
import config from '~/config';
import PaymentService from '~/services/PaymentService';
var PaymentStore = /** @class */ (function () {
    function PaymentStore(transactionStore, siteStore, searchString, history) {
        var _this = this;
        this.transactionStore = transactionStore;
        this.siteStore = siteStore;
        this.searchString = searchString;
        this.history = history;
        this.payments = [];
        this.patientPayments = [];
        this.paymentsCount = 0;
        this.paymentFilters = {
            search: '',
            fromDate: null,
            toDate: null,
            type: null,
            status: null,
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderBy: 'createdAt',
            orderDir: 'DESC'
        };
        this.patientPaymentFilters = {
            search: '',
            fromDate: null,
            toDate: null,
            type: null,
            status: null,
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderBy: 'payments__createdAt',
            orderDir: 'DESC'
        };
        this.filterLabels = {};
        this.paymentsLoading = false;
        this.patientPaymentsLoading = false;
        this.changePaymentFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.paymentFilters = __assign(__assign({}, this.paymentFilters), newFilters);
                        this.filterLabels = __assign(__assign({}, this.filterLabels), newLabels);
                        this.history.push(getURLSearchObject(this.paymentFilters));
                        return [4 /*yield*/, this.getPayments()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.changePatientPaymentFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.patientPaymentFilters = __assign(__assign({}, this.patientPaymentFilters), newFilters);
                        this.filterLabels = __assign(__assign({}, this.filterLabels), newLabels);
                        this.history.push(getURLSearchObject(this.patientPaymentFilters));
                        return [4 /*yield*/, this.getPatientPayments()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getPayments = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.paymentsLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).getAllPayments;
                        _c = [siteId, __assign(__assign({}, this.paymentFilters), { search: this.transactionStore.filters.search }), {}];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.payments = [];
                        this.paymentsCount = 0;
                        this.errorModalStore.setError(e_1);
                        this.paymentsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.payments = resp.items;
                            _this.paymentsCount = resp.count;
                            _this.paymentsLoading = false;
                        });
                        this.passCounts(resp.counts);
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.passCounts = function (counts) {
            _this.transactionStore.paymentsStatusCounts = counts;
        };
        // payments grouped by patient on BE
        this.getPatientPayments = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_2;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.patientPaymentsLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).getAllPatientPayments;
                        _c = [siteId, __assign(__assign({}, this.patientPaymentFilters), { search: this.transactionStore.filters.search }), {}];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.patientPayments = [];
                        this.paymentsCount = 0;
                        this.errorModalStore.setError(e_2);
                        this.patientPaymentsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.patientPayments = resp.items;
                            _this.paymentsCount = resp.count;
                            _this.patientPaymentsLoading = false;
                        });
                        this.passCounts(resp.counts);
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.addPayment = function (payment) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, res, _a, _b, _c, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).addPayment;
                        _c = [siteId, payment];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        res = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 5: return [4 /*yield*/, this.getPayments()];
                    case 6:
                        _d.sent();
                        return [4 /*yield*/, this.getPatientPayments()];
                    case 7:
                        _d.sent();
                        this.toastStore.addToast(new ToastMessage("New payment added"));
                        return [2 /*return*/, res];
                }
            });
        }); };
        this.getPaymentById = function (id) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_4;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).getPaymentById;
                        _c = [siteId, id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _d.sent();
                        this.errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, resp];
                }
            });
        }); };
        this.updatePayment = function (payment) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_5;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).updatePayment;
                        _c = [siteId, payment];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _d.sent();
                        this.errorModalStore.setError(e_5);
                        return [2 /*return*/, null];
                    case 5: return [4 /*yield*/, this.getPayments()];
                    case 6:
                        _d.sent();
                        return [4 /*yield*/, this.getPatientPayments()];
                    case 7:
                        _d.sent();
                        this.toastStore.addToast(new ToastMessage("Payment updated"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.cancelPayment = function (payment) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_6;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PaymentService).cancelPayment;
                        _c = [siteId, payment.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _d.sent();
                        this.errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this.payments = _this.payments.filter(function (x) { return x.id !== payment.id; });
                            for (var _i = 0, _a = _this.patientPayments; _i < _a.length; _i++) {
                                var p = _a[_i];
                                p.payments = p.payments.filter(function (x) { return x.id !== payment.id; });
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Payment cancelled."));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.cancelPaymentWithConfirmation = function (payment) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to cancel payment " + payment.id + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.cancelPayment(payment)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        assignFiltersFromQueryString(searchString, this.paymentFilters);
        assignFiltersFromQueryString(searchString, this.patientPaymentFilters);
    }
    Object.defineProperty(PaymentStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], PaymentStore.prototype, "payments", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "patientPayments", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "paymentsCount", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "paymentFilters", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "patientPaymentFilters", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "filterLabels", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "paymentsLoading", void 0);
    __decorate([
        observable
    ], PaymentStore.prototype, "patientPaymentsLoading", void 0);
    return PaymentStore;
}());
export { PaymentStore };
