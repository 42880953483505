var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiListItem from '@cros/shared/components/misc/RdiListItem';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { createStyles, List, ListItemText, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getAdverseEventsRoute, getDemographicsRoute, getEnrollmentsRoute, getInsurancesRoute, getMedicalHistoryRoute, getMedicalRecordsRoute, getMedicationsRoute, getPhoneInfoRoute, getUserDetailsRoute, getPatientSharedFilesRoute } from '~/routing/Routes';
var styles = function () {
    return createStyles({
        activeProcedureLink: {
            backgroundColor: "" + ColorsEnum.Primary,
            '&:hover': {
                backgroundColor: "rgba(70,170,200,0.85)"
            },
            '&.inverted:hover': {
                color: ColorsEnum.BlueDark + "!important",
                backgroundColor: "white!important",
                border: "2px solid " + ColorsEnum.BlueDark + "!important"
            },
            '& span': {
                color: ColorsEnum.White + " !important"
            }
        }
    });
};
var PatientDetailNavigation = /** @class */ (function (_super) {
    __extends(PatientDetailNavigation, _super);
    function PatientDetailNavigation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tabs = [
            {
                id: 'userdetails',
                label: 'Patient details',
                url: getUserDetailsRoute,
                isIndex: true
            },
            {
                id: 'insurances',
                label: 'Insurances',
                url: getInsurancesRoute
            },
            {
                id: 'demographics',
                label: 'Demographics',
                url: getDemographicsRoute
            },
            {
                id: 'medicalhistory',
                label: 'Medical history',
                url: getMedicalHistoryRoute
            },
            {
                id: 'medicalrecords',
                label: 'Medical records',
                url: getMedicalRecordsRoute
            },
            {
                id: 'medication',
                label: 'Medication',
                url: getMedicationsRoute
            },
            {
                id: 'adverseevents',
                label: 'Adverse events',
                url: getAdverseEventsRoute
            },
            {
                id: 'trials',
                label: 'Trials',
                url: getEnrollmentsRoute
            },
            {
                id: 'phoneInfo',
                label: 'Smartphone info',
                url: getPhoneInfoRoute
            },
            {
                id: 'sharedfiles',
                label: 'Shared files',
                url: getPatientSharedFilesRoute
            }
        ];
        return _this;
    }
    PatientDetailNavigation.prototype.render = function () {
        var _a = this.props, patient = _a.patient, classes = _a.classes, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId;
        return (React.createElement("div", { style: {
                background: '#FAFAFA',
                width: '287px',
                height: '100%',
                overflow: 'auto'
            } },
            React.createElement("div", { style: { padding: '30px 21px 0px 21px' } },
                React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.TrueBlack }, patient.fullName),
                React.createElement(FlexContainer, { style: { marginBottom: '15px' }, margin: 5 },
                    React.createElement(RdiTypography, { variant: "body2", style: { marginRight: '20px' } }, "Patient ID: " + patient.id),
                    React.createElement(RdiTypography, { variant: "body2" }, patient.genderDisplay + ", " + patient.ageDisplay))),
            React.createElement(List, null, this.tabs.map(function (item) { return (React.createElement(RdiListItem, { key: item.id, button: true, component: NavLink, to: item.url(siteId, patient.id, trialId, enrollmentId), exact: true, activeClassName: classes.activeProcedureLink },
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' }, primary: item.label }))); }))));
    };
    PatientDetailNavigation = __decorate([
        observer
    ], PatientDetailNavigation);
    return PatientDetailNavigation;
}(Component));
export default withStyles(styles)(withRouter(PatientDetailNavigation));
