var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import debounce from 'lodash/debounce';
import { action, computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import EnrollmentService from '../services/EnrollmentService';
import SiteRoleTrialService from '../services/SiteRoleTrialService';
import TrialService from '../services/TrialService';
var TrialStore = /** @class */ (function () {
    function TrialStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.trial = null;
        this.enrollments = [];
        this.enrollmentsCount = 0;
        this.enrollmentStatusCounts = {};
        this.patientFilters = {
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            search: '',
            orderBy: 'nextVisit__engagement',
            orderDir: 'ASC'
        };
        this.patientFilterLabels = {};
        this.siteRoleTrial = undefined;
        this.enrollmentsLoading = false;
        this.trialLoading = false;
        this.siteRoleTrialLoading = false;
        this.fileUploadLoading = false;
        this.contactSavingLoading = false;
        this.changeEnrollmentFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.patientFilters = __assign(__assign({}, this.patientFilters), newFilters);
                        this.patientFilterLabels = __assign(__assign({}, this.patientFilterLabels), newLabels);
                        if (!!('search' in newFilters)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getEnrollments()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.searchDebounce.cancel();
                        this.searchDebounce();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getTrial = function (trialId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, authToken, siteId, trial, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.trialLoading = true;
                        _a = this.siteStore, authToken = _a.authToken, siteId = _a.siteId;
                        _c = (_b = TrialService).getTrial;
                        _d = [trialId];
                        return [4 /*yield*/, authToken()];
                    case 1: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), siteId]))];
                    case 2:
                        trial = _e.sent();
                        this.trial = trial;
                        this.trialLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateTrial = function (trial) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, trials, updated, _b, _c, _d, e_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore, trials = _a.trials;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = TrialService).updateTrial;
                        _d = [trial, siteId, trial.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        if (e_1.code === 'validation-exception') {
                            throw e_1;
                        }
                        errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this.trial = updated;
                            var existingIndex = trials.findIndex(function (t) { return t.id === updated.id; });
                            if (existingIndex >= 0) {
                                trials[existingIndex] = updated;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Trial " + this.trial.name + " updated"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.getEnrollments = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        this.enrollmentsLoading = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _b = (_a = EnrollmentService).listEnrollments;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent(), this.siteStore.siteId,
                            this.trial.id,
                            this.patientFilters])];
                    case 3:
                        res = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _c.sent();
                        this.enrollments = [];
                        this.enrollmentsCount = 0;
                        this.errorModalStore.setError(e_2);
                        this.enrollmentsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        this.enrollments = res.items;
                        this.enrollmentsCount = res.totalItems;
                        this.enrollmentStatusCounts = res.counts;
                        this.enrollmentsLoading = false;
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.getSiteRoleTrial = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, _c, e_3;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        this.siteRoleTrialLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, 5, 6]);
                        _b = (_a = SiteRoleTrialService).getSiteRoleTrial;
                        _c = [this.siteStore.siteId,
                            this.trial.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        res = _d.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        e_3 = _d.sent();
                        this.siteRoleTrial = undefined;
                        return [2 /*return*/, false];
                    case 5:
                        this.siteRoleTrialLoading = false;
                        return [7 /*endfinally*/];
                    case 6:
                        runInAction(function () {
                            _this.siteRoleTrial = res;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.updateSiteRoleTrial = function (_a) {
            var links = _a.links, files = _a.files, contact = _a.contact;
            return __awaiter(_this, void 0, Promise, function () {
                var authToken, res, _b, _c, e_4;
                var _this = this;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            authToken = this.accountStore.authToken;
                            this.siteRoleTrialLoading = true;
                            _d.label = 1;
                        case 1:
                            _d.trys.push([1, 4, 5, 6]);
                            _c = (_b = SiteRoleTrialService).updateSiteRoleTrial;
                            return [4 /*yield*/, authToken()];
                        case 2: return [4 /*yield*/, _c.apply(_b, [_d.sent(), this.siteStore.siteId,
                                this.trial.id,
                                links,
                                files,
                                contact])];
                        case 3:
                            res = _d.sent();
                            return [3 /*break*/, 6];
                        case 4:
                            e_4 = _d.sent();
                            return [2 /*return*/, false];
                        case 5:
                            this.siteRoleTrialLoading = false;
                            return [7 /*endfinally*/];
                        case 6:
                            runInAction(function () {
                                _this.siteRoleTrial = res;
                            });
                            return [2 /*return*/, true];
                    }
                });
            });
        };
        this.updateFile = function (file) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, res, _a, _b, _c, e_5;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        this.siteRoleTrialLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, 5, 6]);
                        _b = (_a = SiteRoleTrialService).updateSiteRoleTrialFile;
                        _c = [this.siteStore.siteId,
                            this.trial.id,
                            file];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        res = _d.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        e_5 = _d.sent();
                        return [2 /*return*/, false];
                    case 5:
                        this.siteRoleTrialLoading = false;
                        return [7 /*endfinally*/];
                    case 6:
                        runInAction(function () {
                            _this.siteRoleTrial = res;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.addEmptyFolder = function (file) {
            _this.siteRoleTrial.files.push(file);
        };
        this.addTrialSiteLink = function (link) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.siteRoleTrial) {
                            this.siteRoleTrial = { websiteLinks: [] };
                        }
                        this.siteRoleTrial.websiteLinks = __spreadArrays((_a = this.siteRoleTrial) === null || _a === void 0 ? void 0 : _a.websiteLinks, [link]);
                        return [4 /*yield*/, this.updateSiteRoleTrial({ links: this.siteRoleTrial.websiteLinks })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateTrialSiteLink = function (index, link) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.siteRoleTrial.websiteLinks[index] = link;
                        return [4 /*yield*/, this.updateSiteRoleTrial({
                                links: this.siteRoleTrial.websiteLinks
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteTrialSiteLinkWithConfirmation = function (index) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete link?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.siteRoleTrial.websiteLinks.splice(index, 1);
                                return [4 /*yield*/, this.updateSiteRoleTrial({ links: this.siteRoleTrial.websiteLinks })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.updateTrialSiteContact = function (contact) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.contactSavingLoading = true;
                        return [4 /*yield*/, this.updateSiteRoleTrial({
                                contact: contact
                            })];
                    case 1:
                        _a.sent();
                        this.contactSavingLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.getProtocolLogic = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, trialId, protocol, _a, _b, _c, e_6;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        trialId = this.trial.id;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TrialService).getProtocolOfTrial;
                        _c = [siteId, trialId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        protocol = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _d.sent();
                        this.errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, protocol];
                }
            });
        }); };
        this.uploadFile = function (file) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, trialId, f, fileForUpload, _a, _b, _c, e_7;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        trialId = this.trial.id;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.fileUploadLoading = true;
                        fileForUpload = new File([file.file], file.originalname, {
                            type: file.file.type
                        });
                        _b = (_a = TrialService).uploadTrialFile;
                        _c = [fileForUpload, siteId, trialId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        this.siteStore.toastStore.addToast(new ToastMessage(file.originalname + " uploaded"));
                        runInAction(function () {
                            if (!_this.siteRoleTrial) {
                                _this.siteRoleTrial = { files: [] };
                            }
                            _this.siteRoleTrial.files.push(f);
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _d.sent();
                        this.errorModalStore.setError(e_7);
                        return [3 /*break*/, 5];
                    case 5:
                        this.fileUploadLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.deleteFileWithConfirmation = function (node) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete " + (node.isFolder ? 'folder' : 'file') + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    var authToken, siteId, trialId, token_1, files, e_8;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                authToken = this.accountStore.authToken;
                                siteId = this.siteStore.siteId;
                                trialId = this.trial.id;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, authToken()];
                            case 2:
                                token_1 = _a.sent();
                                files = [];
                                if (node.isFolder) {
                                    files = this.siteRoleTrial.files.filter(function (f) { return f.originalname.startsWith(node.fullname) || node.key === '0'; });
                                }
                                else if (node.file) {
                                    files.push(node.file);
                                }
                                return [4 /*yield*/, Promise.allSettled(files.map(function (f) { return _this.deleteFile(f.id, siteId, trialId, token_1); }))];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_8 = _a.sent();
                                this.errorModalStore.setError(e_8);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.searchDebounce = debounce(this.getEnrollments, autoSaveConfig.autoSaveDelay);
        this.deleteFile = function (fileId, siteId, trialId, authToken) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, TrialService.deleteTrialFile(fileId, siteId, trialId, authToken)];
                    case 1:
                        _a.sent();
                        runInAction(function () {
                            _this.siteRoleTrial.files = _this.siteRoleTrial.files.filter(function (f) { return f.id !== fileId; });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(TrialStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "trialInfo", {
        get: function () {
            return this.trial ? this.trial : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "trialId", {
        get: function () {
            return this.trial ? this.trial.id : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TrialStore.prototype, "siteId", {
        get: function () {
            return this.siteStore.siteId;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], TrialStore.prototype, "trial", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "enrollments", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "enrollmentsCount", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "enrollmentStatusCounts", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "patientFilters", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "patientFilterLabels", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "siteRoleTrial", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "enrollmentsLoading", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "trialLoading", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "siteRoleTrialLoading", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "fileUploadLoading", void 0);
    __decorate([
        observable
    ], TrialStore.prototype, "contactSavingLoading", void 0);
    __decorate([
        computed
    ], TrialStore.prototype, "trialInfo", null);
    __decorate([
        computed
    ], TrialStore.prototype, "trialId", null);
    __decorate([
        computed
    ], TrialStore.prototype, "siteId", null);
    __decorate([
        action
    ], TrialStore.prototype, "getEnrollments", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "getSiteRoleTrial", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "updateSiteRoleTrial", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "updateFile", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "addEmptyFolder", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "addTrialSiteLink", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "updateTrialSiteLink", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "deleteTrialSiteLinkWithConfirmation", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "updateTrialSiteContact", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "uploadFile", void 0);
    __decorate([
        action
    ], TrialStore.prototype, "deleteFileWithConfirmation", void 0);
    return TrialStore;
}());
export default TrialStore;
export var injectTrialStore = inject('trialStore');
