var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiCheckbox from '@cros/shared/components/forms/RdiCheckbox';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { PhonePlatformEnum, PhonePlatformLabelsEnum } from '@cros/shared/constants/enums/PhonePlatformEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { toLowercase } from '@cros/shared/utils/stringUtils';
import { newFieldChange } from '@cros/shared/utils/utils';
import { newUtilValidate } from '@cros/shared/utils/validationUtils';
import { CircularProgress, createStyles, withStyles, withTheme } from '@material-ui/core';
import * as telephoneData from 'country-telephone-data';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import UserStore from '~/stores/UserStore';
import SendTestSmsBtn from './SendTestSms';
var styles = function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return createStyles({
        input: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                '& label': {
                    display: 'none'
                }
            },
            _a),
        mobileHide: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _b),
        threeTwoRow: {},
        inputFlex: {
            flex: '0 0 33%',
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            '&:nth-of-type(2)': (_c = {
                    minWidth: '300px'
                },
                _c[theme.breakpoints.down('xs')] = {
                    flex: '1',
                    minWidth: '100%'
                },
                _c),
            '&:nth-of-type(1)': (_d = {
                    display: function (props) { return "" + (props.leftAligned ? 'none' : undefined); },
                    paddingTop: '15px'
                },
                _d[theme.breakpoints.down('xs')] = {
                    display: 'none'
                },
                _d),
            '&:nth-of-type(3)': (_e = {},
                _e[theme.breakpoints.down('xs')] = {
                    display: 'none'
                },
                _e)
        },
        buttonContainer: (_f = {
                display: 'flex',
                justifyContent: function (props) {
                    return "" + (props.leftAligned ? 'flex-start' : 'center');
                }
            },
            _f[theme.breakpoints.down('xs')] = {
                justifyContent: 'flex-start'
            },
            _f),
        selectedValue: {
            backgroundColor: ColorsEnum.BlackLight,
            color: ColorsEnum.TrueBlack,
            padding: '5px',
            marginRight: '7px!important',
            cursor: 'pointer',
            '&::after': {
                display: 'block',
                content: '"✕"',
                float: 'right',
                padding: '0px 5px 0px 7px'
            }
        }
    });
};
var UserDetails = /** @class */ (function (_super) {
    __extends(UserDetails, _super);
    function UserDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.errors = {};
        _this.labels = {
            phoneNumberPrefix: '',
            phonePlatform: '',
            country: '',
            emergencyContactPhonePrefix: ''
        };
        _this.rules = {
            firstName: 'required|min:2',
            lastName: 'required|min:2',
            email: 'required|email',
            phoneNumberPrefix: 'required',
            phoneNumber: 'required|numeric',
            phonePlatform: 'required',
            hasOwnDevice: 'boolean',
            hasSmsNotificationsEnabled: 'boolean',
            addressLine1: 'required|max:200',
            addressLine2: 'max:200',
            state: 'max:200',
            city: 'required|max:200',
            zip: 'required|max:200',
            country: 'required|max:200',
            emergencyContactName: 'required|max:200',
            emergencyContactPhonePrefix: 'required',
            emergencyContactPhoneNumber: 'required',
            trials: 'required'
        };
        _this.data = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumberPrefix: '',
            phoneNumber: '',
            phonePlatform: '',
            hasOwnDevice: false,
            hasSmsNotificationsEnabled: false,
            addressLine1: '',
            addressLine2: '',
            state: '',
            city: '',
            zip: '',
            country: '',
            emergencyContactName: '',
            emergencyContactPhonePrefix: '',
            emergencyContactPhoneNumber: '',
            trials: []
        };
        _this.saving = false;
        _this.loading = true;
        _this.hasErrors = false;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var patient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        patient = this.props.patient;
                        return [4 /*yield*/, this.userStore.listUserFilters()];
                    case 1:
                        _a.sent();
                        this.assignData(patient);
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (patient) { return __awaiter(_this, void 0, void 0, function () {
            var siteStore, params_1, trial, country;
            return __generator(this, function (_a) {
                siteStore = this.props.siteStore;
                if (!patient) {
                    this.data.country = 'us';
                    this.labels.country = telephoneData.allCountries.find(function (x) { return x.iso2 === 'us'; }).name;
                    this.data.phoneNumberPrefix = '+1';
                    this.labels.phoneNumberPrefix = '+1';
                    this.data.emergencyContactPhonePrefix = '+1';
                    this.labels.emergencyContactPhonePrefix = '+1';
                    params_1 = queryString.parse(this.props.location.search);
                    if (params_1.from) {
                        trial = siteStore.trials.find(function (t) { return t.id === params_1.from; });
                        this.data.trials.push({
                            value: trial.id,
                            label: trial.name
                        });
                        this.trialsChange();
                    }
                    return [2 /*return*/];
                }
                this.data.email = patient.email;
                this.data.lastName = patient.lastName;
                this.data.firstName = patient.firstName;
                this.data.hasOwnDevice = patient.hasOwnDevice;
                this.data.hasSmsNotificationsEnabled = patient.hasSmsNotificationsEnabled;
                this.data.emergencyContactName = patient.emergencyContactName;
                this.data.emergencyContactPhoneNumber = patient.emergencyContactPhoneNumber;
                this.data.emergencyContactPhonePrefix = patient.emergencyContactPhonePrefix;
                this.labels.emergencyContactPhonePrefix = patient.emergencyContactPhonePrefix;
                this.data.phoneNumber = patient.phoneNumber;
                this.data.phoneNumberPrefix = patient.phoneNumberPrefix;
                this.labels.phoneNumberPrefix = patient.phoneNumberPrefix;
                this.data.phonePlatform = patient.phonePlatform;
                this.labels.phonePlatform = PhonePlatformLabelsEnum[patient.phonePlatform];
                this.data.zip = patient.zip;
                this.data.city = patient.city;
                this.data.addressLine1 = patient.addressLine1;
                this.data.addressLine2 = patient.addressLine2;
                this.data.country = patient.country;
                country = telephoneData.allCountries.find(function (x) { return x.iso2 === patient.country; });
                this.labels.country = country ? country.name : '';
                this.data.state = patient.state;
                delete this.rules.trials; // do not validate trials if in edit mode
                return [2 /*return*/];
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return newUtilValidate(_this.data, _this.errors, _this.rules, null, validationErrors, true);
        };
        _this.validatePhoneNumberFields = function () {
            return newUtilValidate({ phoneNumber: _this.data.phoneNumber, phoneNumberPrefix: _this.data.phoneNumberPrefix }, _this.errors, { phoneNumber: _this.rules.phoneNumber, phoneNumberPrefix: _this.rules.phoneNumberPrefix }, null, null, true);
        };
        _this.fieldChange = function (e, field) {
            newFieldChange(e, field, _this.data, _this.labels);
            if (field === 'trials') {
                _this.trialsChange();
            }
        };
        _this.trialsChange = function () { return __awaiter(_this, void 0, void 0, function () {
            var siteStore, protocols, _loop_1, this_1, _i, _a, trial;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        siteStore = this.props.siteStore;
                        return [4 /*yield*/, siteStore.listVisitDefinitions({
                                trialIds: this.data.trials.map(function (t) { return t.value; })
                            })];
                    case 1:
                        protocols = _d.sent();
                        _loop_1 = function (trial) {
                            if (trial.visitDefinitions) {
                                return "continue";
                            }
                            trial.visitDefinitions = (_b = protocols.find(function (p) { return p.trialId === trial.value; })) === null || _b === void 0 ? void 0 : _b.visits;
                            trial.firstVisitIdent = null;
                            trial.firstVisitEngagement = new Date();
                            trial.time = new Date();
                            // default to first visit definition
                            var visitDef = ((_c = trial.visitDefinitions) === null || _c === void 0 ? void 0 : _c.length) > 0
                                ? trial.visitDefinitions[0]
                                : { slug: 'no-visits', name: 'No visit' };
                            trial.firstVisitIdent = visitDef.slug;
                            this_1.labels["trials." + trial.value + ".firstVisitIdent"] = visitDef.name;
                        };
                        this_1 = this;
                        for (_i = 0, _a = this.data.trials; _i < _a.length; _i++) {
                            trial = _a[_i];
                            _loop_1(trial);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.submitForm = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onSuccess, submit, invite, success, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, onSuccess = _a.onSuccess, submit = _a.submit;
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors)
                            return [2 /*return*/];
                        invite = {
                            firstName: this.data.firstName,
                            lastName: this.data.lastName,
                            email: toLowercase(this.data.email),
                            phoneNumberPrefix: this.data.phoneNumberPrefix,
                            phoneNumber: String(this.data.phoneNumber),
                            phonePlatform: this.data.phonePlatform,
                            hasOwnDevice: this.data.hasOwnDevice,
                            hasSmsNotificationsEnabled: this.data.hasSmsNotificationsEnabled,
                            addressLine1: this.data.addressLine1,
                            addressLine2: this.data.addressLine2,
                            city: this.data.city,
                            state: this.data.state,
                            zip: this.data.zip,
                            country: this.data.country,
                            emergencyContactName: this.data.emergencyContactName,
                            emergencyContactPhoneNumber: String(this.data.emergencyContactPhoneNumber),
                            emergencyContactPhonePrefix: this.data.emergencyContactPhonePrefix,
                            trials: this.data.trials.map(function (trial) { return ({
                                trialId: trial.value,
                                firstVisitIdent: trial.firstVisitIdent !== 'no-visits' ? trial.firstVisitIdent : undefined,
                                firstVisitEngagement: trial.firstVisitIdent && trial.firstVisitIdent !== 'no-visits'
                                    ? getDateFromTimeAndDate(trial.time, trial.firstVisitEngagement)
                                    : undefined,
                                providerId: trial.providerId
                            }); })
                        };
                        success = false;
                        this.saving = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, submit(invite)];
                    case 2:
                        success = _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        if (error_1.code === 'validation-exception') {
                            this.hasErrors = this.validateForm(error_1.meta);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        if (this.hasErrors || !success)
                            return [2 /*return*/];
                        return [7 /*endfinally*/];
                    case 5:
                        if (onSuccess) {
                            onSuccess(invite);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.userStore = new UserStore(props.siteStore);
        return _this;
    }
    Object.defineProperty(UserDetails.prototype, "isEdit", {
        get: function () {
            var patient = this.props.patient;
            return !!patient;
        },
        enumerable: false,
        configurable: true
    });
    UserDetails.prototype.checkboxChange = function (val, field) {
        this.data[field] = val;
    };
    UserDetails.prototype.render = function () {
        var _this = this;
        var _a = this.props, classes = _a.classes, patient = _a.patient, siteStore = _a.siteStore, accountStore = _a.accountStore;
        var requestFilters = this.userStore.requestFilters;
        return (React.createElement(React.Fragment, null,
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !this.loading && (React.createElement("form", { noValidate: true, autoComplete: "off", style: { width: '100%' }, onSubmit: this.submitForm },
                React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '15px' } }, "Patient data"),
                React.createElement("div", { className: classes.threeTwoRow },
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Name")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, value: this.data.firstName, errorText: this.errors.firstName, fullWidth: true, label: "Name", placeholder: "Name", autoFocus: true, onChange: function (e) { return _this.fieldChange(e, 'firstName'); }, name: "name" })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Last name")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, value: this.data.lastName, errorText: this.errors.lastName, fullWidth: true, label: "Last name", placeholder: "Last name", onChange: function (e) { return _this.fieldChange(e, 'lastName'); }, name: "lastName" })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Email")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, value: this.data.email, errorText: this.errors.email, type: "email", fullWidth: true, label: "Email", onChange: function (e) { return _this.fieldChange(e, 'email'); }, name: "email", disabled: this.isEdit })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Phone number")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, value: this.data.phoneNumberPrefix, selectedItemLabel: this.labels.phoneNumberPrefix, errorText: this.errors.phoneNumberPrefix, placeholder: "", type: "select", style: { width: '130px', marginRight: '10px', minWidth: 'auto' }, fullWidth: true, label: "Prefix", onChange: function (e) { return _this.fieldChange(e, 'phoneNumberPrefix'); }, options: telephoneData.allCountries.map(function (item) { return ({
                                    label: "+" + item.dialCode,
                                    value: "+" + item.dialCode
                                }); }), name: "phonePrefix" }),
                            React.createElement(RdiInput, { type: "number", className: classes.input, value: this.data.phoneNumber, errorText: this.errors.phoneNumber, fullWidth: true, label: "Phone number", placeholder: "Phone number", onChange: function (e) { return _this.fieldChange(e, 'phoneNumber'); }, name: "phoneNumber", style: { minWidth: 'auto' } })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", style: { marginBottom: '15px' } },
                        React.createElement("div", { className: classes.inputFlex + " " + classes.mobileHide }),
                        React.createElement("div", { style: { alignItems: 'center', justifyContent: 'flex-start' }, className: classes.inputFlex },
                            React.createElement(RdiCheckbox, { containerStyle: { marginBottom: '0px' }, value: this.data.hasSmsNotificationsEnabled, onChange: function (e) { return _this.checkboxChange(e, 'hasSmsNotificationsEnabled'); } }, "SMS notifications"),
                            React.createElement(SendTestSmsBtn, { phoneNumber: "" + this.data.phoneNumberPrefix + this.data.phoneNumber, validatePhoneNumberFunc: this.validatePhoneNumberFields })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Phone platform type")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, value: this.data.phonePlatform, selectedItemLabel: this.labels.phonePlatform, errorText: this.errors.phonePlatform, placeholder: "", type: "select", fullWidth: true, label: "Phone platform type", onChange: function (e) { return _this.fieldChange(e, 'phonePlatform'); }, options: Object.values(PhonePlatformEnum).map(function (key) { return ({
                                    label: PhonePlatformLabelsEnum[key],
                                    value: key
                                }); }), name: "phonePlatform" })),
                        React.createElement("div", { className: classes.inputFlex })),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: classes.inputFlex + " " + classes.mobileHide }),
                        React.createElement("div", { style: { alignItems: 'center', justifyContent: 'flex-start' }, className: classes.inputFlex },
                            React.createElement(RdiCheckbox, { containerStyle: { marginBottom: '0px' }, value: this.data.hasOwnDevice, onChange: function (e) { return _this.checkboxChange(e, 'hasOwnDevice'); } }, "Patient will use their own smartphone")),
                        React.createElement("div", { className: classes.inputFlex }))),
                React.createElement(RdiLine, null),
                React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '15px' } }, "Patient's address"),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Address line 1")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.addressLine1, errorText: this.errors.addressLine1, fullWidth: true, type: "text", label: "Address line 1", onChange: function (e) { return _this.fieldChange(e, 'addressLine1'); }, placeholder: "Street address, P.O. box, company name", name: "addressLine1" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Address line 2")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.addressLine2, errorText: this.errors.addressLine2, fullWidth: true, type: "text", label: "Address line 2", onChange: function (e) { return _this.fieldChange(e, 'addressLine2'); }, placeholder: "Apartment, suite, unit, building, floor, etc.", name: "addressLine2" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "City")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.city, errorText: this.errors.city, fullWidth: true, type: "text", label: "City", placeholder: "City", onChange: function (e) { return _this.fieldChange(e, 'city'); }, name: "city" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "State")),
                    React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.state, errorText: this.errors.state, fullWidth: true, type: "text", label: "State", placeholder: "State", onChange: function (e) { return _this.fieldChange(e, 'state'); }, style: { width: '50%' }, name: "state" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Zip")),
                    React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.zip, errorText: this.errors.zip, fullWidth: true, type: "text", label: "Zip", placeholder: "Zip", onChange: function (e) { return _this.fieldChange(e, 'zip'); }, style: { width: '50%' }, name: "zip" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Country")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.country, selectedItemLabel: this.labels.country, errorText: this.errors.country, fullWidth: true, type: "select", label: "Country", onChange: function (e) { return _this.fieldChange(e, 'country'); }, options: telephoneData.allCountries.map(function (item) { return ({
                                label: "" + item.name,
                                value: "" + item.iso2
                            }); }), name: "country" }))),
                React.createElement(RdiLine, null),
                React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '15px' } }, "Emergency contact"),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Name")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { placeholder: "Name", className: classes.input, value: this.data.emergencyContactName, errorText: this.errors.emergencyContactName, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'emergencyContactName'); }, name: "emergencyContactName", autoComplete: "emergencyContactName" }))),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: "" + classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption" }, "Phone number")),
                    React.createElement("div", { className: classes.inputFlex },
                        React.createElement(RdiInput, { className: classes.input, value: this.data.emergencyContactPhonePrefix, selectedItemLabel: this.labels.emergencyContactPhonePrefix, errorText: this.errors.emergencyContactPhonePrefix, type: "select", style: { width: '130px', marginRight: '10px', minWidth: 'auto' }, fullWidth: true, onChange: function (e) {
                                return _this.fieldChange(e, 'emergencyContactPhonePrefix');
                            }, options: telephoneData.allCountries.map(function (item) { return ({
                                label: "+" + item.dialCode,
                                value: "+" + item.dialCode
                            }); }), name: "emergencyContactPhonePrefix" }),
                        React.createElement(RdiInput, { type: "number", className: classes.input, value: this.data.emergencyContactPhoneNumber, errorText: this.errors.emergencyContactPhoneNumber, fullWidth: true, onChange: function (e) {
                                return _this.fieldChange(e, 'emergencyContactPhoneNumber');
                            }, name: "emergencyContactPhoneNumber", placeholder: "Phone number", autoComplete: "emergencyContactPhoneNumber", style: { minWidth: 'auto' } })),
                    React.createElement("div", { className: classes.inputFlex })),
                React.createElement(RdiLine, null),
                !patient && (React.createElement(React.Fragment, null,
                    React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '15px' } }, "Trial"),
                    React.createElement(FlexContainer, { alignItems: "flex-start" },
                        React.createElement("div", { className: "" + classes.inputFlex },
                            React.createElement(RdiTypography, { variant: "caption" }, "Trial")),
                        React.createElement("div", { className: classes.inputFlex },
                            React.createElement(RdiInput, { className: classes.input, errorText: this.errors.trials, fullWidth: true, type: "select-multiple", placeholder: "Select to add trial...", value: this.data.trials, onChange: function (e) { return _this.fieldChange(e, 'trials'); }, options: siteStore.trials.map(function (x) { return ({
                                    value: x.id,
                                    label: x.name
                                }); }), name: "trials" })),
                        React.createElement("div", { className: classes.inputFlex })),
                    this.data.trials.map(function (trial, index) {
                        var _a;
                        return (React.createElement(React.Fragment, { key: index },
                            React.createElement(RdiLine, null),
                            React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '15px' } },
                                "Schedule first visit for",
                                ' ',
                                siteStore.trials.find(function (t) { return trial.value === t.id; }).name),
                            React.createElement(FlexContainer, { alignItems: "flex-start" },
                                React.createElement("div", { className: "" + classes.inputFlex },
                                    React.createElement(RdiTypography, { variant: "caption" }, "Provider")),
                                React.createElement("div", { className: classes.inputFlex },
                                    React.createElement(RdiInput, { className: classes.input, value: trial.providerId, selectedItemLabel: _this.labels["trials." + trial.value + ".providerId"], errorText: _this.errors["trials." + index + ".providerId"], placeholder: "Select provider", type: "select", fullWidth: true, label: "Prefix", onChange: function (e) {
                                            return _this.fieldChange(e, "trials." + index + ".providerId");
                                        }, options: ((_a = requestFilters.providerId) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                                            label: item.label,
                                            value: item.slug
                                        }); })) || [], name: "providerId" })),
                                React.createElement("div", { className: classes.inputFlex })),
                            React.createElement(FlexContainer, { alignItems: "flex-start" },
                                React.createElement("div", { className: "" + classes.inputFlex },
                                    React.createElement(RdiTypography, { variant: "caption" }, "Visit name")),
                                React.createElement("div", { className: classes.inputFlex },
                                    React.createElement(RdiInput, { className: classes.input, value: trial.firstVisitIdent, selectedItemLabel: _this.labels["trials." + trial.value + ".firstVisitIdent"], errorText: _this.errors["trials." + index + ".firstVisitIdent"], placeholder: "", type: "select", fullWidth: true, label: "Prefix", onChange: function (e) {
                                            return _this.fieldChange(e, "trials." + index + ".firstVisitIdent");
                                        }, options: __spreadArrays([
                                            { name: 'No visit', slug: 'no-visits' }
                                        ], (trial.visitDefinitions ? trial.visitDefinitions : [])).map(function (item) { return ({
                                            label: item.name,
                                            value: item.slug,
                                            trialId: item.trialId
                                        }); }), name: "firstVisitIdent" })),
                                React.createElement("div", { className: classes.inputFlex })),
                            trial.firstVisitIdent && trial.firstVisitIdent !== 'no-visits' && (React.createElement(React.Fragment, null,
                                React.createElement(FlexContainer, { alignItems: "flex-start" },
                                    React.createElement("div", { className: "" + classes.inputFlex },
                                        React.createElement(RdiTypography, { variant: "caption" }, "Date")),
                                    React.createElement("div", { className: classes.inputFlex },
                                        React.createElement(RdiInput, { className: classes.input, value: trial.firstVisitEngagement, errorText: _this.errors["trials." + index + ".firstVisitEngagement"], fullWidth: true, type: "date", onChange: function (e) {
                                                return _this.fieldChange(e, "trials." + index + ".firstVisitEngagement");
                                            }, name: "firstVisitEngagement" })),
                                    React.createElement("div", { className: classes.inputFlex })),
                                React.createElement(FlexContainer, { alignItems: "flex-start" },
                                    React.createElement("div", { className: "" + classes.inputFlex },
                                        React.createElement(RdiTypography, { variant: "caption" }, "Time")),
                                    React.createElement("div", { className: classes.inputFlex },
                                        React.createElement(RdiInput, { className: classes.input, value: trial.time, errorText: _this.errors["trials." + index + ".time"], fullWidth: true, type: "time", onChange: function (e) {
                                                return _this.fieldChange(e, "trials." + index + ".time");
                                            }, name: "time" })),
                                    React.createElement("div", { className: classes.inputFlex }))))));
                    }))),
                !patient && React.createElement(RdiLine, null),
                this.hasErrors && (React.createElement(RdiErrorBox, null, "Please correctly fill out the fields marked above.")),
                React.createElement("div", { className: classes.buttonContainer },
                    React.createElement(RdiButton, { style: { minWidth: '210px' }, loading: this.saving, submit: true, permissions: [
                            {
                                aclOp: this.isEdit ? AclOpEnum.UPDATE : AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.PATIENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, this.isEdit ? 'Save' : 'Invite patient'))))));
    };
    __decorate([
        observable
    ], UserDetails.prototype, "errors", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "labels", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "rules", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "data", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "saving", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "loading", void 0);
    __decorate([
        observable
    ], UserDetails.prototype, "hasErrors", void 0);
    UserDetails = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], UserDetails);
    return UserDetails;
}(Component));
export default withTheme(withStyles(styles)(withRouter(UserDetails)));
