var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { DatapointEnum } from '@cros/shared/constants/enums/DatapointEnum';
import { DatapointTemplateEnum } from '@cros/shared/constants/enums/DatapointTemplateEnum';
import { DatapointWrapTypeEnum } from '@cros/shared/constants/enums/DatapointWrapTypeEnum';
import { EditStateEnum } from '@cros/shared/constants/enums/EditStateEnum';
import { ProcedureStatusEnum } from '@cros/shared/constants/enums/ProcedureStatusEnum';
import { VisitStatusEnum } from '@cros/shared/constants/enums/VisitStatusEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import CheckmarkIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { computed, observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import VisitCheckBox from '~/components/visit-procedures/VisitCheckBox';
import VisitDate from '~/components/visit-procedures/VisitDate';
import VisitDesign from '~/components/visit-procedures/VisitDesign';
import VisitFile from '~/components/visit-procedures/VisitFile';
import VisitNote from '~/components/visit-procedures/VisitNote';
import VisitNumeric from '~/components/visit-procedures/VisitNumeric';
import VisitRadioGroup from '~/components/visit-procedures/VisitRadioGroup';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import ProcedureStore from '~/stores/PatientStore/ProcedureStore';
import { injectVisitStore } from '~/stores/PatientStore/VisitStore';
import { injectSiteStore } from '~/stores/SiteStore';
import IpDispensingLogPage from '../IpDispensingLogPage';
import IpDosingLog from '../IpDosingLogPage';
import AdverseEvents from '../patient-detail/AdverseEventsPage';
import MedicationListPage from '../patient-detail/MedicationListPage';
var VisitProcedure = /** @class */ (function (_super) {
    __extends(VisitProcedure, _super);
    function VisitProcedure(props) {
        var _this = _super.call(this, props) || this;
        _this.saving = false;
        _this.onDataPointChange = function (dataPoint, value) {
            _this.procedureStore.onDataPointChange(dataPoint, value);
        };
        _this.submitProcedure = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, procedure, history, res, nextProcedureUrl;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        _a = this.props, procedure = _a.procedure, history = _a.history;
                        this.saving = true;
                        return [4 /*yield*/, this.procedureStore.finalizeProcedure()];
                    case 1:
                        res = _b.sent();
                        this.saving = false;
                        if (!res) {
                            return [2 /*return*/];
                        }
                        if (res.status === ProcedureStatusEnum.FINALIZED ||
                            res.status === ProcedureStatusEnum.COMPLETE) {
                            nextProcedureUrl = this.procedureStore.getNextProcedureUrl(procedure);
                            if (nextProcedureUrl) {
                                history.push(nextProcedureUrl);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var _a = _this.props, siteStore = _a.siteStore, procedure = _a.procedure, visitStore = _a.visitStore, enrollmentStore = _a.enrollmentStore;
        _this.procedureStore = new ProcedureStore(siteStore, procedure, visitStore, enrollmentStore);
        return _this;
    }
    Object.defineProperty(VisitProcedure.prototype, "canCompleteProcedure", {
        get: function () {
            var _a = this.props, procedure = _a.procedure, visit = _a.visitStore.visit;
            return (procedure.status !== ProcedureStatusEnum.FINALIZED &&
                procedure.status !== ProcedureStatusEnum.COMPLETE &&
                visit.engagement &&
                visit.status !== VisitStatusEnum.SCHEDULED &&
                visit.status !== VisitStatusEnum.UNSCHEDULED);
        },
        enumerable: false,
        configurable: true
    });
    VisitProcedure.prototype.render = function () {
        var _this = this;
        var _a = this.props, procedure = _a.procedure, _b = _a.procedure, label = _b.label, datapoints = _b.datapoints, isComplete = _b.isComplete, hasErrors = _b.hasErrors, accountStore = _a.accountStore, siteStore = _a.siteStore;
        var editState = this.procedureStore.editState;
        return (React.createElement(Provider, { procedureStore: this.procedureStore },
            React.createElement("form", { noValidate: true, onSubmit: this.submitProcedure, className: "hideScrollbar", style: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement("div", { style: {
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        paddingRight: '45px'
                    } },
                    React.createElement(RdiTypography, { style: { width: '100%' }, variant: "h6", color: TypographyColorsEnum.Primary }, label),
                    editState === EditStateEnum.SAVING && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Success }, "Saving...")),
                    editState === EditStateEnum.EDIT && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, "Edited"))),
                React.createElement("div", { style: {
                        width: '100%',
                        paddingTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        boxSizing: 'border-box',
                        paddingRight: '20px',
                        flexFlow: 'wrap',
                        height: '100%'
                    }, className: "procedureForm" },
                    React.createElement("div", { style: { width: '100%' } },
                        isComplete && (React.createElement(FlexContainer, { margin: 10, style: { fontSize: '12px', marginBottom: '15px' } }, isComplete && (React.createElement(React.Fragment, null,
                            React.createElement(CheckmarkIcon, { style: {
                                    color: ColorsEnum.IconsGreen,
                                    fontSize: '19px'
                                } }),
                            React.createElement("span", { style: { color: ColorsEnum.IconsGreen } }, "Procedure completed"))))),
                        datapoints.map(function (dataPoint, index) {
                            // temporary hack removing last HR's
                            if (datapoints.length - 1 === index &&
                                dataPoint.calculatedType === DatapointEnum.design &&
                                dataPoint.template_name === DatapointTemplateEnum.hr) {
                                return null;
                            }
                            if (procedure.shouldSkip(dataPoint)) {
                                return null;
                            }
                            switch (dataPoint.calculatedType) {
                                case DatapointEnum.boolean:
                                    switch (dataPoint.template_name) {
                                        case DatapointTemplateEnum.checkbox:
                                            return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                                React.createElement(VisitCheckBox, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                        case DatapointTemplateEnum.radio:
                                            return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                                React.createElement(VisitRadioGroup, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                        default:
                                            return null;
                                    }
                                case DatapointEnum.design:
                                case DatapointEnum.error:
                                    return (React.createElement(DataPointWrapper, { key: dataPoint.slug || index, dataPoint: dataPoint },
                                        React.createElement(VisitDesign, { dataPoint: dataPoint })));
                                case DatapointEnum.radio:
                                    return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                        React.createElement(VisitRadioGroup, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                case DatapointEnum.datetime:
                                    return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                        React.createElement(VisitDate, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                case DatapointEnum.numeric:
                                    return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                        React.createElement(VisitNumeric, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                case DatapointEnum.upload:
                                    return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                        React.createElement(VisitFile, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                case DatapointEnum.text:
                                    switch (dataPoint.template_name) {
                                        case DatapointTemplateEnum.textbox:
                                        case DatapointTemplateEnum.text:
                                            return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                                React.createElement(VisitNote, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                        case DatapointTemplateEnum.time:
                                            return (React.createElement(DataPointWrapper, { key: dataPoint.slug, dataPoint: dataPoint },
                                                React.createElement(VisitDate, { dataPoint: dataPoint, onDataPointChange: _this.onDataPointChange })));
                                        default:
                                            return null;
                                    }
                                case DatapointEnum.adverse_events_form:
                                    return (React.createElement(DataPointWrapper, { dataPoint: dataPoint, key: dataPoint.slug },
                                        React.createElement(AdverseEvents, { visitId: "1" })));
                                case DatapointEnum.medication_changes_form:
                                    return (React.createElement(DataPointWrapper, { dataPoint: dataPoint, key: dataPoint.slug },
                                        React.createElement(MedicationListPage, { visitId: "1", key: dataPoint.slug })));
                                case DatapointEnum.investigation_product_dispensing_form:
                                    return (React.createElement(DataPointWrapper, { dataPoint: dataPoint, key: dataPoint.slug },
                                        React.createElement(IpDispensingLogPage, { key: dataPoint.slug })));
                                case DatapointEnum.investigation_product_dosing_form:
                                    return (React.createElement(DataPointWrapper, { dataPoint: dataPoint, key: dataPoint.slug },
                                        React.createElement(IpDosingLog, { key: dataPoint.slug })));
                                default:
                                    return null;
                            }
                        }))),
                this.canCompleteProcedure && (React.createElement("div", { style: { marginRight: '25px' } },
                    React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '0px' } }),
                    React.createElement(FlexContainer, { width: "100%", margin: 30, style: { marginTop: '10px' } },
                        this.canCompleteProcedure && (React.createElement(RdiButton, { fullWidth: false, margin: "none", submit: true, style: {
                                backgroundColor: ColorsEnum.IconsGreen,
                                height: '57px'
                            }, loading: this.saving, permissions: [
                                {
                                    aclOp: AclOpEnum.UPDATE,
                                    aclModule: AclModuleEnum.PROCEDURE
                                }
                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                            React.createElement(CheckmarkIcon, { style: { marginRight: '10px' } }),
                            React.createElement(React.Fragment, null, "Complete procedure"))),
                        hasErrors && (React.createElement(FlexContainer, { margin: 5, style: { alignItems: 'center' } },
                            React.createElement(WarningIcon, { style: { color: 'red', fontSize: '19px' } }),
                            React.createElement(RdiTypography, { variant: "body2", style: { fontSize: '12px' } },
                                React.createElement("span", { style: { color: 'red' } }, "Procedure has errors"))))))))));
    };
    __decorate([
        observable
    ], VisitProcedure.prototype, "saving", void 0);
    __decorate([
        computed
    ], VisitProcedure.prototype, "canCompleteProcedure", null);
    VisitProcedure = __decorate([
        injectSiteStore,
        injectEnrollmentStore,
        injectAccountStore,
        injectVisitStore,
        observer
    ], VisitProcedure);
    return VisitProcedure;
}(Component));
var DataPointWrapper = function (props) {
    var dataPoint = props.dataPoint, style = props.style, children = props.children;
    return (React.createElement(FlexContainer, { style: __assign({ alignItems: 'flex-start', display: dataPoint.template_meta &&
                dataPoint.template_meta.global_wrap === DatapointWrapTypeEnum.nowrap
                ? 'inline-flex'
                : 'flex' }, style), width: dataPoint.template_meta &&
            dataPoint.template_meta.global_wrap === DatapointWrapTypeEnum.nowrap
            ? 'fit-content'
            : '100%' }, children));
};
export default withRouter(VisitProcedure);
