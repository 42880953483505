var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { action, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import AdverseEventService from '~/services/AdverseEventService';
var AdverseEventsStore = /** @class */ (function () {
    function AdverseEventsStore(patientStore) {
        var _this = this;
        this.patientStore = patientStore;
        this.events = [];
        this.eventsCount = 0;
        this.adverseEventFilters = {
            orderBy: 'startDate',
            orderDir: 'DESC',
            rowsPerPage: config.defaultRowsPerPage,
            page: 1
        };
        this.adverseEventsLoading = true;
        this.toggleExpandCell = function (index) {
            _this.events[index].expanded = !_this.events[index].expanded;
        };
        this.changeAdverseEventsFilters = function (newFilters) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.adverseEventFilters = __assign(__assign({}, this.adverseEventFilters), newFilters);
                        return [4 /*yield*/, this.listAdverseEvents()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.uploadSaeForm = function (saeForm, adverseEventId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, ae, _b, _c, _d, e_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = AdverseEventService).uploadSaeForm;
                        _d = [saeForm,
                            siteId,
                            patientId,
                            adverseEventId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        ae = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, ae];
                }
            });
        }); };
        // public deleteSaeForm = async (f: UploadedFile, adverseEventId: string) => {
        //   // TODO: implement
        // };
        this.listAdverseEvents = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, patientId, req, _b, _c, _d, e_2;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, patientId = _a.patientId;
                        this.adverseEventsLoading = true;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = AdverseEventService).listAdverseEvents;
                        _d = [siteId,
                            patientId,
                            this.adverseEventFilters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        req = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _e.sent();
                        this.adverseEventsLoading = false;
                        errorModalStore.setError(e_2);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.events = req.items;
                            _this.eventsCount = req.count;
                            _this.adverseEventsLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.addAdverseEvent = function (adverseEvent, saeForm) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, newAE, _b, _c, _d, e_3;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 6, , 7]);
                        _c = (_b = AdverseEventService).addAdverseEvent;
                        _d = [adverseEvent,
                            patientId,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        newAE = _e.sent();
                        if (!saeForm) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.updateAdverseEvent(newAE, true, [saeForm])];
                    case 4:
                        newAE = _e.sent();
                        _e.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_3 = _e.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 7: return [4 /*yield*/, this.listAdverseEvents()];
                    case 8:
                        _e.sent();
                        toastStore.addToast(new ToastMessage("New adverse event " + adverseEvent.text + " added"));
                        return [2 /*return*/, newAE];
                }
            });
        }); };
        this.updateAdverseEvent = function (adverseEvent, dontShowTask, files) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, updatedAE, calls, _i, files_1, f, results, _b, _c, _d, e_4, existingEventindex;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 5, , 6]);
                        calls = [];
                        for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                            f = files_1[_i];
                            // if the file is new
                            if (f.file) {
                                calls.push(this.uploadSaeForm(f.file, adverseEvent.id));
                            }
                            // if the file has been marked for deletion
                            // if (f.deleted) {
                            //   await this.deleteSaeForm(f, adverseEvent.id);
                            // }
                        }
                        return [4 /*yield*/, Promise.all(calls)];
                    case 2:
                        results = _e.sent();
                        if (results && results[0]) {
                            adverseEvent.saeFormId = results[0].id;
                        }
                        _c = (_b = AdverseEventService).updateAdverseEvent;
                        _d = [adverseEvent,
                            siteId,
                            patientId,
                            adverseEvent.id];
                        return [4 /*yield*/, authToken()];
                    case 3: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 4:
                        updatedAE = _e.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_4 = _e.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 6:
                        existingEventindex = this.events.findIndex(function (ev) { return ev.id === adverseEvent.id; });
                        if (existingEventindex >= 0) {
                            this.events[existingEventindex] = updatedAE;
                        }
                        if (!dontShowTask) {
                            toastStore.addToast(new ToastMessage("Adverse event " + updatedAE.text + " updated"));
                        }
                        return [2 /*return*/, updatedAE];
                }
            });
        }); };
        this.deleteAdverseEventWithConfirmation = function (adverseEvent) {
            var confirmationModalStore = _this.patientStore.confirmationModalStore;
            confirmationModalStore.openModal("Do you really want to remove adverse event " + adverseEvent.text + "?", function () { return _this.deleteAdverseEvent(adverseEvent); });
        };
        this.deleteAdverseEvent = function (adverseEvent) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, _b, _c, _d, e_5;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = AdverseEventService).deleteAdverseEvent;
                        _d = [siteId,
                            patientId,
                            adverseEvent.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _e.sent();
                        errorModalStore.setError(e_5);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.events = _this.events.filter(function (ev) { return ev.id !== adverseEvent.id; });
                            _this.eventsCount -= 1;
                        });
                        toastStore.addToast(new ToastMessage("Adverse event " + adverseEvent.text + " removed"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.getAdverseEvent = function (eventId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, ae, _b, _c, _d, e_6;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = AdverseEventService).getAdverseEvent;
                        _d = [siteId, patientId, eventId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        ae = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _e.sent();
                        errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, ae];
                }
            });
        }); };
    }
    __decorate([
        observable
    ], AdverseEventsStore.prototype, "events", void 0);
    __decorate([
        observable
    ], AdverseEventsStore.prototype, "eventsCount", void 0);
    __decorate([
        observable
    ], AdverseEventsStore.prototype, "adverseEventFilters", void 0);
    __decorate([
        observable
    ], AdverseEventsStore.prototype, "adverseEventsLoading", void 0);
    __decorate([
        action
    ], AdverseEventsStore.prototype, "toggleExpandCell", void 0);
    return AdverseEventsStore;
}());
export { AdverseEventsStore };
export var injectAdverseEventsStore = inject('adverseEventsStore');
