var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { IconButton, Menu, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import AddEnrollmentModal from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/AddEnrollmentModal';
import ManageProviderModal from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/ManageProviderModal';
import { getEnrollmentsRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
var PatientEnrollmentsPage = /** @class */ (function (_super) {
    __extends(PatientEnrollmentsPage, _super);
    function PatientEnrollmentsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'name',
                label: 'Name',
                sortable: false
            },
            {
                id: 'provider',
                label: 'Provider',
                sortable: false
            },
            {
                id: 'progress',
                label: 'Progress',
                sortable: false
            },
            {
                id: 'actions',
                label: '',
                sortable: false,
                width: '50px'
            }
        ];
        _this.getAddRoute = function () {
            var url = _this.props.match.url;
            return url + "/new";
        };
        _this.getManageProviderRoute = function (enrollment) {
            var url = _this.props.match.url;
            return url + "/manage/" + enrollment.trialId + "--" + enrollment.id;
        };
        _this.menuRef = null;
        _this.enrollmentInEdit = null;
        _this.handleMenuClick = function (event, enrollment) {
            _this.menuRef = event.currentTarget;
            _this.enrollmentInEdit = enrollment;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.manageProvider = function () {
            _this.handleMenuClose();
            var history = _this.props.history;
            history.push(_this.getManageProviderRoute(_this.enrollmentInEdit));
        };
        _this.onShowDeleteConfirmation = function () {
            var enrollment = _this.enrollmentInEdit;
            _this.handleMenuClose();
            var _a = _this.props, patientStore = _a.patientStore, enrollmentId = _a.match.params.enrollmentId, history = _a.history;
            patientStore.deleteEnrollmentWithConfirmation(enrollment, function () {
                if (enrollment.id === enrollmentId) {
                    history.push(getEnrollmentsRoute(patientStore.siteId, patientStore.patient.id, patientStore.patient.enrollments[0].trialId, patientStore.patient.enrollments[0].id));
                }
            });
        };
        return _this;
    }
    Object.defineProperty(PatientEnrollmentsPage.prototype, "rowMenuOpen", {
        get: function () {
            return !!this.menuRef;
        },
        enumerable: false,
        configurable: true
    });
    PatientEnrollmentsPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, enrollments = _a.patientStore.patient.enrollments, siteStore = _a.siteStore, accountStore = _a.accountStore;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    marginBottom: 30,
                    width: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary, style: { marginBottom: '45px' } }, "Trials"),
                React.createElement(FlexContainer, { alignItems: "center", justifyContent: "space-between", style: { marginBottom: '50px' } },
                    React.createElement(RdiTypography, { variant: "caption", style: { padding: '0px' } }, enrollments.length + " trial" + (enrollments.length === 1 ? '' : 's')),
                    React.createElement(RdiButton, { name: "new-enrollment", to: this.getAddRoute, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.PATIENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Add trial")),
                React.createElement("div", { style: { marginTop: '20px', overflowY: 'auto' } },
                    React.createElement(RdiTable, { stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
                        React.createElement(TableBody, null,
                            enrollments.length === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 5 }, "No results"))),
                            enrollments.map(function (enrollment, index) {
                                var _a, _b;
                                return (React.createElement(React.Fragment, { key: index },
                                    React.createElement(TableRow, { key: index },
                                        React.createElement(TableCell, null,
                                            React.createElement(RdiTypography, { color: TypographyColorsEnum.BlackSemiDark, variant: "body2" }, (_a = enrollment.trial) === null || _a === void 0 ? void 0 : _a.name)),
                                        React.createElement(TableCell, null,
                                            React.createElement(RdiTypography, { variant: "body2" }, (_b = enrollment.provider) === null || _b === void 0 ? void 0 : _b.fullName)),
                                        React.createElement(TableCell, null,
                                            React.createElement(RdiTypography, { variant: "body2" },
                                                enrollment.progress,
                                                "%")),
                                        React.createElement(TableCell, null,
                                            React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, enrollment); } },
                                                React.createElement(MoreVertIcon, null))))));
                            })))),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: this.rowMenuOpen, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, onClose: this.handleMenuClose },
                    React.createElement(RdiMenuItem, { onClick: this.manageProvider, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.ENROLLMENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Manage provider")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.ENROLLMENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.MANAGE_ENROLLMENT_ROUTE },
                        React.createElement(ManageProviderModal, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.ADD_ENROLLMENT_ROUTE },
                        React.createElement(AddEnrollmentModal, null))))));
    };
    __decorate([
        observable
    ], PatientEnrollmentsPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], PatientEnrollmentsPage.prototype, "enrollmentInEdit", void 0);
    __decorate([
        computed
    ], PatientEnrollmentsPage.prototype, "rowMenuOpen", null);
    PatientEnrollmentsPage = __decorate([
        injectSiteStore,
        injectPatientStore,
        injectAccountStore,
        observer
    ], PatientEnrollmentsPage);
    return PatientEnrollmentsPage;
}(Component));
export default withRouter(PatientEnrollmentsPage);
