var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { EditStateEnum } from '@cros/shared/constants/enums/EditStateEnum';
import { ProcedureStatusEnum } from '@cros/shared/constants/enums/ProcedureStatusEnum';
import { Data } from '@cros/shared/types/models/Datapoint';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { addHours, parseISO } from 'date-fns';
import debounce from 'lodash/debounce';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import { getVisitDetailRoute } from '../../routing/Routes';
import VisitService from '../../services/VisitService';
var ProcedureStore = /** @class */ (function () {
    function ProcedureStore(siteStore, procedure, visitStore, enrollmentStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.procedure = procedure;
        this.visitStore = visitStore;
        this.enrollmentStore = enrollmentStore;
        this.editState = EditStateEnum.READ;
        this.changedDatapoints = [];
        this.finalizeProcedure = function () { return __awaiter(_this, void 0, Promise, function () {
            var res, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.procedure.status === ProcedureStatusEnum.FINALIZED ||
                            this.procedure.status === ProcedureStatusEnum.COMPLETE) {
                            return [2 /*return*/, null];
                        }
                        this.autoSave.cancel();
                        this.procedure.status = ProcedureStatusEnum.FINALIZING;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.updateVisitProcedure(this.procedure, this.procedure.datapoints.filter(function (x) { return x.slug; }), ProcedureStatusEnum.COMPLETE)];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 4:
                        if (!res) {
                            return [2 /*return*/, null];
                        }
                        runInAction(function () {
                            // update state everywhere else
                            if (_this.procedure.status === ProcedureStatusEnum.COMPLETE) {
                                var _a = _this.enrollmentStore, visits = _a.visitListStore.visits, nextVisit = _a.enrollment.nextVisit;
                                var v = visits.find(function (x) { return x.id === _this.visit.id; });
                                v.progress = _this.visit.progressDetail;
                                if ((nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.id) === _this.visit.id) {
                                    nextVisit.progress = _this.visit.progressDetail;
                                }
                            }
                        });
                        if (res.status === ProcedureStatusEnum.COMPLETE) {
                            this.toastStore.addToast(new ToastMessage("Procedure " + res.label + " completed."));
                        }
                        return [2 /*return*/, res];
                }
            });
        }); };
        this.updateVisitProcedure = function (updatedProcedure, updatedDataPoints, status) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, trialId, enrollmentId, authToken, updatedProcedureResult, _b, _c, _d, e_2;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        authToken = this.accountStore.authToken;
                        this.editState = EditStateEnum.SAVING;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).updateVisitProcedure;
                        _d = [siteId,
                            trialId,
                            enrollmentId,
                            this.visit.id,
                            updatedProcedure.slug,
                            updatedDataPoints];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), status]))];
                    case 3:
                        updatedProcedureResult = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _e.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5:
                        if (this.editState !== EditStateEnum.SAVING) {
                            // we've edited since we made the API call, don't reverse the changes, they will be autosaved again;
                            // ignore if finalizing
                            return [2 /*return*/, null];
                        }
                        runInAction(function () {
                            _this.procedure.status = updatedProcedureResult.status;
                            var i = 0;
                            for (var _i = 0, _a = _this.procedure.datapoints; _i < _a.length; _i++) {
                                var datapoint = _a[_i];
                                datapoint.errors = updatedProcedureResult.datapoints[i].errors;
                                datapoint.touched = updatedProcedureResult.datapoints[i].touched;
                                datapoint.data = updatedProcedureResult.datapoints[i].data;
                                i += 1;
                            }
                            _this.editState = EditStateEnum.READ;
                        });
                        return [2 /*return*/, updatedProcedureResult];
                }
            });
        }); };
        this.onDataPointChange = function (datapoint, data) { return __awaiter(_this, void 0, void 0, function () {
            var dataPoint, changedDatapoints;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // return, we've already finalized and saved, no need
                        if (this.procedure.status === ProcedureStatusEnum.FINALIZING) {
                            return [2 /*return*/];
                        }
                        dataPoint = this.procedure.datapoints.find(function (dp) { return dp.slug === datapoint.slug; });
                        return [4 /*yield*/, this.executeOnDataChange(dataPoint, data)];
                    case 1:
                        changedDatapoints = _a.sent();
                        runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                dataPoint.data = new Data(data);
                                this.procedure.datapoints = __spreadArrays(this.procedure.datapoints);
                                this.editState = EditStateEnum.EDIT;
                                return [2 /*return*/];
                            });
                        }); });
                        return [4 /*yield*/, this.markDatapointsAsDirty(__spreadArrays(changedDatapoints, [datapoint]))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.executeOnDataChange = function (dataPoint, data) { return __awaiter(_this, void 0, Promise, function () {
            var dataPointToModify, action_value_1;
            return __generator(this, function (_a) {
                if (!dataPoint.template_meta) {
                    return [2 /*return*/, []];
                }
                // check if we have any actions on select
                if (dataPoint.template_meta.action_value) {
                    action_value_1 = dataPoint.template_meta.action_value;
                    // check if value matches
                    if (action_value_1.on_value === data.value) {
                        dataPointToModify = this.procedure.datapoints.find(function (dp) { return dp.slug === action_value_1.manipulate_slug; });
                        if (!dataPointToModify.data.value) {
                            switch (action_value_1.default_to) {
                                case 'visit_date':
                                    if (!this.visit.engagement) {
                                        break;
                                    }
                                    dataPointToModify.data.value = this.visit.engagement.toISOString();
                                    if (action_value_1.skew) {
                                        if (action_value_1.skew.hours) {
                                            dataPointToModify.data.value = addHours(parseISO(dataPointToModify.data.value), action_value_1.skew.hours).toISOString();
                                        }
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
                if (dataPointToModify) {
                    return [2 /*return*/, [dataPointToModify]];
                }
                return [2 /*return*/, []];
            });
        }); };
        this.markDatapointsAsDirty = function (datapoints) { return __awaiter(_this, void 0, void 0, function () {
            var _loop_1, this_1, _i, datapoints_1, dp;
            return __generator(this, function (_a) {
                _loop_1 = function (dp) {
                    var existing = this_1.changedDatapoints.find(function (dataPoint) { return dataPoint === dp.slug; });
                    if (!existing) {
                        this_1.changedDatapoints.push(dp.slug);
                    }
                };
                this_1 = this;
                for (_i = 0, datapoints_1 = datapoints; _i < datapoints_1.length; _i++) {
                    dp = datapoints_1[_i];
                    _loop_1(dp);
                }
                this.autoSave();
                return [2 /*return*/];
            });
        }); };
        this.autoSave = debounce(function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            var updatedDataPoints, _loop_2, this_2, _i, _a, updatedDataPointSlug;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        updatedDataPoints = [];
                                        _loop_2 = function (updatedDataPointSlug) {
                                            updatedDataPoints.push(this_2.procedure.datapoints.find(function (dp) { return dp.slug === updatedDataPointSlug; }));
                                        };
                                        this_2 = this;
                                        for (_i = 0, _a = this.changedDatapoints; _i < _a.length; _i++) {
                                            updatedDataPointSlug = _a[_i];
                                            _loop_2(updatedDataPointSlug);
                                        }
                                        if (this.procedure.status === ProcedureStatusEnum.FINALIZING) {
                                            return [2 /*return*/];
                                        }
                                        return [4 /*yield*/, this.updateVisitProcedure(this.procedure, updatedDataPoints)];
                                    case 1:
                                        _b.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, autoSaveConfig.autoSaveDelay);
        this.uploadFile = function (file, datapoint) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, trialId, enrollmentId, authToken, res, _b, _c, _d, e_3;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        authToken = this.accountStore.authToken;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).uploadFile;
                        _d = [file,
                            siteId,
                            this.visit.id,
                            trialId,
                            enrollmentId,
                            this.procedure.slug,
                            datapoint.slug];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        res = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _e.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, res];
                }
            });
        }); };
        this.getNextProcedureUrl = function (procedure) {
            var _a = _this.enrollmentStore, trialId = _a.trialId, enrollmentId = _a.enrollmentId, patientId = _a.patientId, siteId = _a.siteId;
            var currentIndex = _this.visit.procedures.findIndex(function (x) { return x.slug === procedure.slug; });
            var nextProcedure = _this.visit.procedures.length > currentIndex + 1
                ? _this.visit.procedures[currentIndex + 1]
                : null;
            if (!nextProcedure) {
                return null;
            }
            return getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, _this.visit.id) + "/" + nextProcedure.slug;
        };
    }
    Object.defineProperty(ProcedureStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProcedureStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProcedureStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProcedureStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProcedureStore.prototype, "visit", {
        get: function () {
            return this.visitStore.visit;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], ProcedureStore.prototype, "editState", void 0);
    __decorate([
        observable
    ], ProcedureStore.prototype, "changedDatapoints", void 0);
    __decorate([
        computed
    ], ProcedureStore.prototype, "visit", null);
    return ProcedureStore;
}());
export default ProcedureStore;
export var injectProcedureStore = inject('procedureStore');
