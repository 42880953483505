var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import PageContent from '@cros/shared/components/layout/content/PageContent';
import NavigationDrawerWrapper from '@cros/shared/components/layout/NavigationDrawerWrapper';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import NavigationDrawer from '~/components/layout/NavigationDrawer';
import ChangePasswordPage from '~/pages/account/ChangePasswordPage';
import SmsNotificationsPage from '~/pages/account/SmsNotificationsPage';
import AuditLogListPage from '~/pages/site/site-detail/audit-log/AuditLogListPage';
import BillingApprovalPage from '~/pages/site/site-detail/billing-approval/BillingApprovalPage';
import BillingOverviewPage from '~/pages/site/site-detail/billing-overview/BillListPage';
import PatientListPage from '~/pages/site/site-detail/patients/PatientListPage';
import PatientPage from '~/pages/site/site-detail/patients/PatientPage';
import PaymentsIndexPage from '~/pages/site/site-detail/payments/PaymentsIndexPage';
import SiteSettingsPage from '~/pages/site/site-detail/settings/SiteSettingsPage';
import TasksIndexPage from '~/pages/site/site-detail/tasks/TasksIndexPage';
import AddPatientPage from '~/pages/site/site-detail/trials/trial-detail/AddPatientPage';
import UserManagementPage from '~/pages/site/site-detail/users/UserManagementPage';
import { AccountRoutes, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import ClinicDocumentsPage from './clinic-documents/ClinicDocumentsPage';
import ClinicDashboardStatisticPage from './dashboard-statistic/ClinicDashboardStatisticPage';
import TrialListPage from './trials/TrialListPage';
var SiteDetail = /** @class */ (function (_super) {
    __extends(SiteDetail, _super);
    function SiteDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SiteDetail.prototype.render = function () {
        var trialsLoading = this.props.siteStore.trialsLoading;
        return (React.createElement(PageContent, null,
            React.createElement(NavigationDrawerWrapper, { NavigationDrawer: NavigationDrawer }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: SiteRoutes.CLINIC_DASHBOARD_STATISTIC },
                    React.createElement(ClinicDashboardStatisticPage, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.NEW_PATIENT_ROUTE },
                    React.createElement(AddPatientPage, null)),
                React.createElement(Route, { path: SiteRoutes.PATIENT_ROUTE }, !trialsLoading && React.createElement(PatientPage, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.SITE_ROUTE }, "SITE-DETAIL : not implemented"),
                React.createElement(Route, { exact: true, path: AccountRoutes.CHANGE_PASSWORD_ROUTE },
                    React.createElement(ChangePasswordPage, null)),
                React.createElement(Route, { path: AccountRoutes.SMS_TASKS_INDEX_ROUTE },
                    React.createElement(SmsNotificationsPage, null)),
                React.createElement(Route, { path: SiteRoutes.TRIAL_LIST_ROUTE },
                    React.createElement(TrialListPage, null)),
                React.createElement(Route, { path: SiteRoutes.TASKS_INDEX_ROUTE },
                    React.createElement(TasksIndexPage, null)),
                React.createElement(Route, { path: SiteRoutes.PATIENT_LIST_ROUTE },
                    React.createElement(PatientListPage, null)),
                React.createElement(Route, { path: SiteRoutes.USERS_ROUTE },
                    React.createElement(UserManagementPage, null)),
                React.createElement(Route, { path: SiteRoutes.AUDIT_LOG_LIST_ROUTE },
                    React.createElement(AuditLogListPage, null)),
                React.createElement(Route, { path: SiteRoutes.PAYMENTS_INDEX_ROUTE },
                    React.createElement(PaymentsIndexPage, null)),
                React.createElement(Route, { path: SiteRoutes.SITE_SETTINGS_ROUTE },
                    React.createElement(SiteSettingsPage, null)),
                React.createElement(Route, { path: SiteRoutes.BILLING_APPROVAL_ROUTE },
                    React.createElement(BillingApprovalPage, null)),
                React.createElement(Route, { path: SiteRoutes.BILLING_OVERVIEW_ROUTE },
                    React.createElement(BillingOverviewPage, null)),
                React.createElement(Route, { path: SiteRoutes.CLINIC_DOCUMNETS_ROUTE },
                    React.createElement(ClinicDocumentsPage, null)))));
    };
    SiteDetail = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], SiteDetail);
    return SiteDetail;
}(Component));
export default withRouter(SiteDetail);
