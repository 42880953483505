var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import RdiJsxParser from '@cros/shared/components/layout/RdiJsxParser';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import { Note, NoteTypeEnum } from '@cros/shared/types/models/Note';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { CircularProgress, createStyles, Link, Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { NoteStore } from '~/stores/NoteStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTaskStore } from '~/stores/TaskStore';
import UserStore from '~/stores/UserStore';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import { utilValidate } from '@cros/shared//utils/validationUtils';
import config from '~/config';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        textBubble: {
            padding: '0.5em 0.75em',
            borderRadius: '1em',
            boxShadow: '0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);',
            maxWidth: '450px'
        },
        thread: (_a = {
                overflow: 'auto'
            },
            _a[theme.breakpoints.up('md')] = {
                height: 'calc(100vh - 700px)'
            },
            _a[theme.breakpoints.down('sm')] = {
                height: 'calc(100vh - 540px)'
            },
            _a.minHeight = '315px',
            _a),
        fileInput: (_b = {},
            _b[theme.breakpoints.up('md')] = {
                flexDirection: 'row !important'
            },
            _b[theme.breakpoints.down('md')] = {
                flexDirection: 'column'
            },
            _b)
    });
};
var NotesList = /** @class */ (function (_super) {
    __extends(NotesList, _super);
    function NotesList(props) {
        var _this = _super.call(this, props) || this;
        _this.saving = false;
        _this.task = null;
        _this.loading = true;
        _this.data = {
            note: {
                value: '',
                error: null,
                rule: 'string',
                mentions: []
            },
            message: {
                value: '',
                error: null,
                rule: 'string|required_without:note|min:2',
                mentions: []
            },
            files: {
                value: null,
                error: null
            }
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var taskStore, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        taskStore = this.props.taskStore;
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, taskStore.getTaskById(this.taskId)];
                    case 1:
                        _a.task = _b.sent();
                        if (!this.task) {
                            this.onClose();
                            return [2 /*return*/];
                        }
                        this.noteStore.task = this.task;
                        return [4 /*yield*/, this.noteStore.listNotes()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.userStore.listUsers()];
                    case 3:
                        _b.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split("/detail")[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        _this.addNote = function (e, type) { return __awaiter(_this, void 0, void 0, function () {
            var hasErrors, newNote, note;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        hasErrors = this.validateForm();
                        if (hasErrors) {
                            return [2 /*return*/];
                        }
                        note = new Note({
                            note: type === NoteTypeEnum.Note ? this.data.note.value : this.data.message.value,
                            mentions: type === NoteTypeEnum.Note ? this.data.note.mentions : this.data.message.mentions,
                            taskId: this.task.id,
                            type: type
                        });
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.noteStore.addNote(note, this.data.files.value)];
                    case 2:
                        newNote = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.saving = false;
                        if (!newNote) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 4:
                        // reset note data
                        this.data.note.value = '';
                        this.data.note.mentions = [];
                        this.data.message.value = '';
                        this.data.message.mentions = [];
                        this.data.files.value = [];
                        return [2 /*return*/];
                }
            });
        }); };
        _this.generateUsersList = function (users) {
            var h = {};
            users.forEach(function (u) {
                return !h[u.firstName + u.lastName]
                    ? (h[u.firstName + u.lastName] = [u])
                    : h[u.firstName + u.lastName].push(u);
            });
            var list = [];
            Object.entries(h).forEach(function (_a) {
                var k = _a[0];
                var hasEmail = h[k].length >= 2;
                while (h[k].length >= 1) {
                    var x = h[k].pop();
                    list.push(__assign({ display: "" + x.firstName + x.lastName + " " + (hasEmail ? "(" + x.email + ")" : '') }, x));
                }
            });
            return list;
        };
        _this.uploadFile = function (event) {
            var f = event.files && event.files.length ? event.files[0] : undefined;
            if (!_this.data.files.value) {
                _this.data.files.value = [];
            }
            if (f.size > 104857600) {
                // 100mb
                _this.data.files.error = 'File to larg (100mb max)';
                return;
            }
            _this.data.files.value.push(new UploadedFile({
                originalname: f.name,
                mimetype: f.type,
                size: f.size,
                url: null,
                file: f
            }));
        };
        _this.removeFile = function (item) {
            _this.data.files.value.find(function (x) { return x.originalname === item.originalname; }).deleted = true;
            _this.data.files.error = '';
        };
        var siteStore = _this.props.siteStore;
        _this.noteStore = new NoteStore(siteStore);
        _this.userStore = new UserStore(siteStore);
        return _this;
    }
    Object.defineProperty(NotesList.prototype, "taskId", {
        get: function () {
            var params = this.props.match.params;
            return params.taskId;
        },
        enumerable: false,
        configurable: true
    });
    NotesList.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, accountDetail = _c.siteStore.accountStore.accountDetail, classes = _c.classes;
        var _d = this.noteStore, notes = _d.notes, deleteNoteWithConfirmation = _d.deleteNoteWithConfirmation;
        var users = this.userStore.users;
        var usersList = this.generateUsersList(users);
        return (React.createElement("div", null,
            React.createElement("div", { style: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement("div", { style: { marginBottom: '25px' } },
                    React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h6" }, "Notes")),
                !this.loading && (React.createElement("div", { style: { height: '100%', paddingRight: '20px' } },
                    React.createElement("form", { onSubmit: function (e) { return _this.addNote(e, NoteTypeEnum.Note); } },
                        React.createElement(RdiInput, { label: "Add a new note to staff", name: "note", value: this.data.note.value, type: "mentions", style: { minHeight: '90px' }, errorText: this.data.note.error, onChange: function (e) { return _this.fieldChange(e, 'note'); }, options: usersList, placeholder: "Add a note. You can also tag your coworker by using the @ symbol..." }),
                        React.createElement("div", null,
                            React.createElement(RdiButton, { submit: true, loading: this.saving, style: { width: '256px' } }, "Add note"))),
                    React.createElement(RdiLine, null),
                    React.createElement("div", { className: classes.thread }, notes.map(function (note, index) {
                        var _a, _b;
                        return (React.createElement("div", { style: {
                                width: '100%',
                                marginBottom: '8px',
                                padding: '8px',
                                display: 'flex',
                                justifyContent: note.type === NoteTypeEnum.Message && note.ownerId === accountDetail.id
                                    ? 'flex-end'
                                    : 'flex-start'
                            }, key: index },
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    React.createElement("b", { style: { fontSize: '11px', margin: '0 0 4px 4px' } },
                                        note.type === NoteTypeEnum.Note && (React.createElement(Tooltip, { title: "Note" },
                                            React.createElement(InfoIcon, { color: "primary", style: {
                                                    fontSize: '16px',
                                                    verticalAlign: 'bottom',
                                                    marginBottom: '2px',
                                                    marginRight: '4px'
                                                } }))),
                                        note.owner ? note.owner.fullName : '')),
                                React.createElement("div", { className: classes.textBubble }, note.isSystemGenerated ? (React.createElement(RdiJsxParser, null, note.note)) : (React.createElement(RdiInput, { disabled: true, borderWidth: "0px", value: note.note, type: "mentions", options: usersList, style: {
                                        padding: '0px',
                                        marginBottom: '0px'
                                    } }))),
                                React.createElement("div", { style: { fontSize: '11px', fontStyle: 'italic' } }, formatDatetimeLong(note.createdAt)),
                                ((_a = note.attachments) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement("div", null, (_b = note.attachments) === null || _b === void 0 ? void 0 : _b.map(function (attachment, fileIndex) { return (React.createElement(RdiFileDescription, { config: config, key: fileIndex, file: attachment, style: { fontSize: '12px' } })); }))) : null,
                                React.createElement("div", null, index === 0 &&
                                    note.ownerId === accountDetail.id &&
                                    note.type === NoteTypeEnum.Note && (React.createElement(Link, { className: "removeNoteLink", style: { cursor: 'pointer' }, onClick: function () { return deleteNoteWithConfirmation(note); } }, "Remove"))))));
                    })),
                    React.createElement("form", { onSubmit: function (e) { return _this.addNote(e, NoteTypeEnum.Message); } },
                        React.createElement(FlexContainer, { style: { marginTop: '10px' }, margin: 15 },
                            React.createElement(RdiInput, { label: "Message patient", name: "message", value: this.data.message.value, type: "mentions", style: { minHeight: '90px', marginTop: '8px' }, errorText: this.data.message.error, onChange: function (e) { return _this.fieldChange(e, 'message'); }, options: usersList, placeholder: "Add a message. You can also tag your coworker by using the @ symbol..." })),
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement(RdiButton, { submit: true, loading: this.saving, style: { width: '256px' } }, "Add message"),
                            React.createElement(FlexContainer, { flexDirection: "column", className: classes.fileInput },
                                React.createElement(RdiInput, { type: "file", label: "Add attachment", name: "file", value: null, inputProps: { accept: 'image/*,application/pdf,.xls*,.doc*,.txt' }, style: {
                                        flexShrink: 4,
                                        marginLeft: '24px',
                                        marginRight: '16px',
                                        marginBottom: 0
                                    }, errorText: this.data.files.error, onChange: this.uploadFile, disabled: (_b = (_a = this.data.files.value) === null || _a === void 0 ? void 0 : _a.filter(function (x) { return !x.deleted; })) === null || _b === void 0 ? void 0 : _b.length }),
                                this.data.files.value &&
                                    this.data.files.value
                                        .filter(function (x) { return !x.deleted; })
                                        .map(function (item, index) { return (React.createElement(RdiFileDescription, { config: config, key: index, file: item, onRemove: function () { return _this.removeFile(item); } })); }))))))),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], NotesList.prototype, "saving", void 0);
    __decorate([
        observable
    ], NotesList.prototype, "task", void 0);
    __decorate([
        observable
    ], NotesList.prototype, "loading", void 0);
    __decorate([
        observable
    ], NotesList.prototype, "data", void 0);
    __decorate([
        computed
    ], NotesList.prototype, "taskId", null);
    NotesList = __decorate([
        injectSiteStore,
        injectTaskStore,
        observer
    ], NotesList);
    return NotesList;
}(Component));
export default withStyles(styles)(withRouter(NotesList));
