var config = {
    apiUrl: process.env.REACT_APP_CROS_API_URL,
    environment: process.env.REACT_APP_CROS_DASHBOARD_ENVIRONMENT,
    sentryDsn: process.env.REACT_APP_CROS_DASHBOARD_SENTRY_DSN,
    // Cognito
    cognitoPoolId: process.env.REACT_APP_CROS_USERS_COGNITO_POOL_ID,
    cognitoPoolRegion: process.env.REACT_APP_CROS_USERS_COGNITO_REGION,
    cognitoPoolClientId: process.env.REACT_APP_CROS_USERS_COGNITO_CLIENT_ID,
    // Compile-time variables
    version: process.env.CROS_DASHBOARD_VERSION,
    release: process.env.CROS_DASHBOARD_RELEASE,
    buildTime: process.env.CROS_DASHBOARD_BUILD_TIME,
    defaultRowsPerPage: process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
        ? parseInt(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE, 10)
        : 25,
    weekStartsOn: 1 // 0 - sunday, 1 - monday, ...
};
export default config;
